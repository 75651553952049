/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useSaveBuoyInspectionMutation,
  useUpdateBuoyInspectionMutation,
} from "../../../api/buoy_inspections";
import { useGetInspectionsQuery } from "../../../api/inspections";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { SCHEDULED } from "../../../constants/workTypes";
import { formatStartOfDay } from "../../../Utils";

const initialState = {
  inspection_id: null,
  start_date: moment(),
};

const NewForm = ({ onSave, selected, onClose, buoyId, workType, title }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);

  const [saveRecord, saveRecordResult] = useSaveBuoyInspectionMutation();
  const [updateRecord, updateRecordResult] = useUpdateBuoyInspectionMutation();
  const getInspectionsResult = useGetInspectionsQuery({
    pageNo: 1,
    pageSize: 10000,
    workType,
  });
  const handleSubmit = () => {
    if (selected)
      updateRecord({
        id: selected.id,
        buoyId,
        body: {
          ...newRecord,
          start_date: formatStartOfDay(newRecord.start_date),
          inspection_id: newRecord.inspection_id?.id,
        },
      });
    else
      saveRecord({
        buoyId,
        body: {
          ...newRecord,
          start_date: newRecord.start_date.format(),
          inspection_id: newRecord.inspection_id?.id,
        },
        workType,
      });
  };

  const options =
    getInspectionsResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord({
        inspection_id:
          options.find((x) => x.id === parseInt(selected.inspection_id)) || "",
        start_date: moment(selected.start_date) || "",
      });
    } else {
      setNewRecord(initialState);
    }
  }, [selected]);
  useEffect(() => {
    if (saveRecordResult.isSuccess || updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [saveRecordResult.data, updateRecordResult.data]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Link {title} to Buoys
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Link {title}</DialogTitle>
        <DialogContent>
          <Autocomplete
            value={newRecord.inspection_id || null}
            sx={{ my: 3 }}
            key={newRecord.inspection_id?.id}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={`Select ${
                    workType === SCHEDULED ? "Inspection" : "Maintenance"
                  }`}
                  variant="outlined"
                  fullWidth
                />
              );
            }}
            onChange={(e, value) =>
              setNewRecord((prev) => ({ ...prev, inspection_id: value }))
            }
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Select Start Date"
              value={newRecord.start_date}
              onChange={(value) =>
                setNewRecord((prev) => ({ ...prev, start_date: value }))
              }
              format="Do MMM YYYY"
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={saveRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
