/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch } from "react-redux";
import { useSignUpUserMutation } from "../../api/auth";
import { useEffect, useState } from "react";
import { resetAuth, updateAuth } from "../../providers/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { showAlert } from "../../providers/alertSlice";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [signUpUser, signUpUserResult] = useSignUpUserMutation({});
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirm_password) {
      dispatch(
        showAlert({
          variant: "error",
          message: "Passwords are not matching.",
        })
      );
      return;
    }
    const payload = { ...formData };
    delete payload.confirm_password;
    signUpUser(payload);
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  useEffect(() => {
    if (signUpUserResult.isSuccess && signUpUserResult.data) {
      dispatch(
        updateAuth({
          accessToken: signUpUserResult.data.accessToken,
        })
      );
      navigate(routes.ROOT);
    }
  }, [signUpUserResult.isSuccess]);

  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={formData.username}
            onChange={(e) => updateFormData("username", e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={(e) => updateFormData("email", e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={(e) => updateFormData("password", e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.confirm_password}
            onChange={(e) => updateFormData("confirm_password", e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !(
                formData.email &&
                formData.username &&
                formData.password &&
                formData.confirm_password
              )
            }
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={routes.LOGIN} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to={routes.LOGIN} variant="body">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
