/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@mui/material";
import React from "react";

const Home = () => {
  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item lg={12} md={12}>
        <Typography variant="h4">Welcome</Typography>
      </Grid>
    </Grid>
  );
};

export default Home;
