/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useSaveInspectionMutation,
  useUpdateInspectionMutation,
} from "../../../api/inspections";
const initialState = {
  name: "",
  frequency: 0,
};

const NewForm = ({ onSave, selected, onClose, workType, title }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);

  const [saveRecord, saveRecordResult] = useSaveInspectionMutation();
  const [updateRecord, updateRecordResult] = useUpdateInspectionMutation();

  const handleSubmit = () => {
    if (selected) updateRecord({ id: selected.id, body: newRecord });
    else saveRecord({ body: newRecord, workType });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord({
        name: selected.name || "",
        frequency: selected.frequency || "",
        checklist: selected.checklist,
      });
    } else {
      setNewRecord(initialState);
    }
  }, [selected]);
  useEffect(() => {
    if (saveRecordResult.isSuccess || updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [saveRecordResult.data, updateRecordResult.data]);

  const canSubmit = () => {
    if (saveRecordResult.isLoading) return false;

    return newRecord.name && parseInt(newRecord.frequency) > 0;
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add New {title}
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Save {title}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newRecord.name}
            onChange={(e) =>
              setNewRecord((prev) => ({ ...prev, name: e.target.value }))
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Frequency in months"
            value={newRecord.frequency}
            type="number"
            onChange={(e) =>
              setNewRecord((prev) => ({
                ...prev,
                frequency: parseInt(e.target.value)
                  ? Math.abs(e.target.value)
                  : null,
              }))
            }
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <Autocomplete
            multiple
            options={[]}
            defaultValue={newRecord.checklist?.data || []}
            value={newRecord.checklist?.data || []}
            freeSolo
            onChange={(event, newValue) => {
              setNewRecord((prev) => ({
                ...prev,
                checklist: { data: newValue },
              }));
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size=""
                label="Press enter to add check points"
                placeholder="Todo list"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!canSubmit()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
