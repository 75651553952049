/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { usePostponeInspectionMutation } from "../../../api/periodic_inspections";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const initialState = {
  postpone_reason: "",
  postponed_to: null,
};

const PostponeForm = ({ onSave, selected, onClose, title }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);

  const [updateRecord, updateRecordResult] = usePostponeInspectionMutation();
  const handleSubmit = () => {
    updateRecord({ id: selected.id, body: newRecord });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord((prev) => ({
        ...prev,
      }));
    }
  }, [selected]);

  useEffect(() => {
    if (updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [updateRecordResult.data]);

  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Postpone {title}</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Postpone to"
              value={newRecord.postponed_to}
              onChange={(value) =>
                setNewRecord((prev) => ({ ...prev, postponed_to: value }))
              }
              format="Do MMM YYYY"
              sx={{ width: "100%", mb: 2, mt: 1 }}
              disablePast
            />
          </LocalizationProvider>
          <TextField
            label="Reason for postpone"
            value={newRecord.postpone_reason}
            onChange={(e) =>
              setNewRecord((prev) => ({
                ...prev,
                postpone_reason: e.target.value,
              }))
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={updateRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PostponeForm;
