import * as React from "react";
import { styled, Box, Button, Grid } from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { MainListItems, UserActions } from "./MenuItems";
import { Outlet } from "react-router-dom";
import { useGetSelfQuery } from "../../api/user";
import { useDispatch, useSelector } from "react-redux";
import { resetMasqueradingAuth } from "../../providers/userSlice";
import { routes } from "../../routes";
import styles from "./BaseLayout.module.css";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function BaseLayout() {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useGetSelfQuery();
  const { masqueradingToken } = useSelector((state) => state.user.data);
  const onStopClick = () => {
    dispatch(resetMasqueradingAuth());
    window.location.href = routes.ROOT;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Smart Buoy
          </Typography>
          {user.data && (
            <Typography variant="body1" sx={{ mr: 2 }}>
              Welcome, {user.data.user.username}
            </Typography>
          )}
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems />
          <Divider sx={{ my: 1 }} />
          <UserActions />
        </List>
      </Drawer>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3} sx={{ mt: 4 }}>
          <Grid item xs={12} md={12} lg={12}>
            {masqueradingToken && user.data && (
              <Box className={styles.masqueradeStrip}>
                You are viewing as &nbsp;<b>{user.data.user.username}</b>. Any
                operation that you would be doing will be considered in &nbsp;
                <b>{user.data.user.username}'s</b>&nbsp; account.
                <Button
                  onClick={onStopClick}
                  sx={{ ml: "auto" }}
                  variant="contained"
                  size="small"
                  color="secondary"
                >
                  Stop Masquerading
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        <Outlet />
      </Box>
    </Box>
  );
}
