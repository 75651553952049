/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DateFormat, DateTimeFormat, formatDateTime } from "../../../Utils";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { filterByDates } from "../Util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetBuoysQuery } from "../../../api/buoys";
import DisplayTableRow from "../../../Utils/DisplayTableRow/DisplayTableRow";

const BuoysReportsTable = () => {
  const [from, setFrom] = useState(moment().subtract(3, "months"));
  const [to, setTo] = useState(moment());
  const [columnsType, setColumnsType] = useState("basic");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [row, setRow] = useState(null);

  const buoys = useGetBuoysQuery({}, { refetchOnMountOrArgChange: true });

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const chartContainerRef = useRef(null);

  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };
  const basicColumns = [
    { field: "name", headerName: "Buoy Name", flex: 1 },
    { field: "username", headerName: "Department User", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "commissioning_date",
      headerName: "Commissioning Date",
      type: "date",
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.commissioning_date
            ? formatDateTime(params.row.commissioning_date)
            : ""}
        </>
      ),
    },

    { field: "model", headerName: "Model", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];
  const additionalColumns = [
    { field: "ais", headerName: "AIS", flex: 1 },
    { field: "color", headerName: "Color", flex: 1 },
    { field: "currency", headerName: "Currency", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "dimensions", headerName: "Dimensions", flex: 1 },
    { field: "gps_location", headerName: "GPS Location", flex: 1 },
    { field: "dimensions", headerName: "Dimensions", flex: 1 },
    { field: "light", headerName: "Light", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "shape", headerName: "Shape", flex: 1 },
  ];
  const columns =
    columnsType === "basic"
      ? basicColumns
      : [...basicColumns, ...additionalColumns];

  const records = (buoys.data?.data || []).map((x) => {
    return {
      ...x,
      commissioning_date: x.commissioning_date
        ? new Date(x.commissioning_date)
        : null,
    };
  });

  useEffect(() => {
    let updatedData = filterByDates(records, from, to, "created_at");
    setFilteredRecords(updatedData);
  }, [from, to, buoys.data?.data]);

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From Date"
            value={from}
            onChange={(value) => setFrom(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) => to && moment(date).isAfter(moment(to))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To Date"
            value={to}
            onChange={(value) => setTo(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) =>
              from && moment(date).isBefore(moment(from))
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={3.5}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={columnsType}
            onChange={(e, value) => setColumnsType(value)}
          >
            <FormControlLabel
              value="basic"
              control={<Radio />}
              label="Basic Details"
            />
            <FormControlLabel
              value="full"
              control={<Radio />}
              label="Full Details"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <Button variant="outlined" onClick={downloadAsPDF}>
          Download as PDF
        </Button>
      </Grid>
      <Grid item lg={12} md={12}>
        <Box sx={{ mt: 2 }} ref={chartContainerRef}>
          <DataGrid
            rows={filteredRecords}
            columns={columns}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            loading={buoys.isFetching}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Buoys - ${moment().format(DateTimeFormat)}.csv`,
                },
                printOptions: {
                  fileName: `Buoys - ${moment().format(DateTimeFormat)}.csv`,
                },
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={(params) => setRow(params.row)}
          />
        </Box>
        {row && (
          <DisplayTableRow
            onClose={() => setRow(null)}
            row={row}
            columns={columns}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default BuoysReportsTable;
