import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const breakdownsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    completeBreakdown: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/breakdowns/${id}/complete`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    getBreakdowns: builder.query({
      query: ({ type, buoyId }) =>
        addParamsToUrl(`v1/breakdowns`, {
          type,
          buoyId,
        }),
      onQueryStarted: requestHandler,
    }),
    getBreakdownById: builder.query({
      query: ({ id }) => `v1/breakdowns/${id}`,
      onQueryStarted: requestHandler,
    }),
    deleteBreakdown: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/breakdowns/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    saveBreakdown: builder.mutation({
      query: ({ body }) => ({
        url: "v1/breakdowns",
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    getBreakDownMeta: builder.query({
      query: () => `v1/breakdowns/meta`,
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useCompleteBreakdownMutation,
  useGetBreakdownByIdQuery,
  useGetBreakdownsQuery,
  useDeleteBreakdownMutation,
  useSaveBreakdownMutation,
  useLazyGetBreakdownsQuery,
  useGetBreakDownMetaQuery,
} = breakdownsApi;
