/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetRolesQuery } from "../../api/roles";
import {
  useSaveCustomerMutation,
  useUpdateCustomerMutation,
} from "../../api/customers";
const initialUser = { username: "", email: "", role_id: "" };

const NewCustomer = ({ onSave, customer, onClose }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newUser, setNewUser] = useState(initialUser);
  const roles = useGetRolesQuery({});
  const [saveCustomer, saveCustomerResult] = useSaveCustomerMutation();
  const [updateCustomer, updateCustomerResult] = useUpdateCustomerMutation();

  const handleAddUser = () => {
    if (customer) updateCustomer({ id: customer.id, body: newUser });
    else saveCustomer(newUser);
  };

  useEffect(() => {
    if (customer) {
      setOpenDialog(true);
      setNewUser({
        username: customer.username || "",
        email: customer.email || "",
        role_id: customer.role_id || "",
      });
    } else {
      setNewUser(initialUser);
    }
  }, [customer]);
  useEffect(() => {
    if (saveCustomerResult.isSuccess || updateCustomerResult.isSuccess) {
      onSave();
      onClose();
      setNewUser(initialUser);
      setOpenDialog(false);
    }
  }, [saveCustomerResult.data, updateCustomerResult.data]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        // startIcon={<AddCircleOutlineIcon />}
        onClick={() => setOpenDialog(true)}
      >
        Add New User
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newUser.username}
            onChange={(e) =>
              setNewUser({ ...newUser, username: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              onChange={(e) =>
                setNewUser({ ...newUser, role_id: e.target.value })
              }
              value={newUser.role_id}
            >
              {roles.data &&
                roles.data.roles.map((role) => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            color="primary"
            disabled={saveCustomerResult.isLoading}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewCustomer;
