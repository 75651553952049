import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: "v1/auth/login",
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    signUpUser: builder.mutation({
      query: (body) => ({
        url: "v1/auth/register",
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    forgotPassword: builder.query({
      query: ({ email }) =>
        addParamsToUrl("v1/auth/password/reset_link", { email }),
      onQueryStarted: requestHandler,
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "v1/auth/password",
        method: "PUT",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignUpUserMutation,
  useLazyForgotPasswordQuery,
  useResetPasswordMutation,
} = authApi;
