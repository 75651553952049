import { useEffect, useRef } from "react";
import cx from "classnames";
import styles from "./RawHTML.module.css";

const RawHTML = (props) => {
  const elRef = useRef(null);
  useEffect(() => {
    if (elRef.current) {
      elRef.current.querySelectorAll("a[href]").forEach((anchorTag) => {
        if (!anchorTag.getAttribute("target")) {
          anchorTag.setAttribute("target", "_blank");
        }
        if (anchorTag.getAttribute("data-api-returntype") === "File") {
          anchorTag.setAttribute("download", "download");
        }
      });
    }
  }, [props.html]);

  return (
    <div
      ref={elRef}
      className={cx(props.className, styles.content)}
      dangerouslySetInnerHTML={{ __html: props.html }}
      id={props.id}
    ></div>
  );
};

export default RawHTML;
