/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import CustomersTable from "./CustomersTable";
import NewCustomer from "./NewCustomer";
import { useState } from "react";

function ManageUsers() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedCustomer, setSelectCustomer] = useState(null);
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={10} lg={10}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Manage Users
          </Typography>
          <NewCustomer
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelectCustomer(null)}
            customer={selectedCustomer}
          />
          <CustomersTable
            refreshCount={refreshCount}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
            onSelect={(user) => {
              setSelectCustomer(user);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ManageUsers;
