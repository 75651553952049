import { Grid, Typography } from "@mui/material";

const Designs = () => {
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid
        item
        xs={12}
        md={10}
        lg={10}
        display={"flex"}
        justifyContent={"center"}
      >
        <Typography variant="h6">This feature is coming soon</Typography>
      </Grid>
    </Grid>
  );
};
export default Designs;
