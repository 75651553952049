/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch } from "react-redux";
import { useLoginUserMutation } from "../../api/auth";
import { useEffect, useState } from "react";
import { resetAuth, updateAuth } from "../../providers/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loginUser, loginUserResult] = useLoginUserMutation({});
  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(formData);
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);
  useEffect(() => {
    if (loginUserResult.isSuccess && loginUserResult.data) {
      dispatch(
        updateAuth({
          accessToken: loginUserResult.data.accessToken,
        })
      );
      navigate(routes.ROOT);
    }
  }, [loginUserResult.isSuccess]);

  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={(e) => updateFormData("email", e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={(e) => updateFormData("password", e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={routes.FORGOT_PASSWORD} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              {/* <Link to={routes.SIGN_UP} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
