import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import styles from "./Alerts.module.css";
import cx from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import RawHTML from "../../Utils/RawHtml/RawHtml";

const AlertSnackbar = (props) => {
  const {
    message,
    onClose,
    variant,
    className,
    closeOnTimeout,
    anchorOrigin,
    ...others
  } = props;

  return (
    <Snackbar
      anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "right" }}
      {...(closeOnTimeout === false ? {} : { onClose })}
      {...others}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        className={cx(styles.alertContent, styles[variant], className)}
        classes={{ message: styles.alertMessage }}
        message={
          <span id="client-snackbar" className={styles.messageContainer}>
            {/* <MessageTypeIcon
              className={styles.messageIcon}
              type={props.variant}
            /> */}
            <RawHTML className={styles.message} html={message} />
            {/* <div className={styles.message}>{message}</div> */}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={styles.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

AlertSnackbar.defaultProps = {
  className: "",
  autoHideDuration: 4000,
};

export default AlertSnackbar;
