import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../providers/userSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { useGetSelfQuery } from "../../api/user";
import {
  ADMIN,
  DEPARTMENT_USER,
  READONLY_ADMIN,
  SUPER_ADMIN,
} from "../../constants/roles";
import SupportIcon from "@mui/icons-material/Support";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
export const MainListItems = () => {
  const navigate = useNavigate();
  const { data } = useGetSelfQuery();
  const apps = () => {
    if (!data || !data.user) return [];

    switch (data.user.role) {
      case ADMIN:
        return [
          {
            name: "Dashboard",
            route: routes.DASHBOARD,
            icon: <DashboardIcon />,
          },
          {
            name: "Users",
            route: routes.CUSTOMERS,
            icon: <PeopleIcon />,
          },
          {
            name: "Buoys",
            route: routes.BUOYS,
            icon: <SupportIcon />,
          },
          {
            name: "Inspections",
            route: routes.INSPECTIONS.replace(":workType", "scheduled"),
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Maintenance",
            route: routes.INSPECTIONS.replace(":workType", "maintenance"),
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Spares",
            route: routes.SPARES,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Breakdowns",
            route: routes.BREAKDOWNS,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Reports",
            route: routes.REPORTS,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Design",
            route: routes.DESIGNS,
            icon: <CheckCircleOutlineIcon />,
          },
        ];
      case SUPER_ADMIN:
        return [
          {
            name: "Users",
            route: routes.CUSTOMERS,
          },
        ];
      case DEPARTMENT_USER:
        return [
          {
            name: "Buoys",
            route: routes.BUOYS,
            icon: <SupportIcon />,
          },
          {
            name: "Inspections",
            route: routes.INSPECTIONS.replace(":workType", "scheduled"),
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Maintenance",
            route: routes.INSPECTIONS.replace(":workType", "maintenance"),
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Spares",
            route: routes.SPARES,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Breakdowns",
            route: routes.BREAKDOWNS,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Design",
            route: routes.DESIGNS,
            icon: <CheckCircleOutlineIcon />,
          },
          {
            name: "Reports",
            route: routes.REPORTS,
            icon: <CheckCircleOutlineIcon />,
          },
        ];
      case READONLY_ADMIN:
        return [
          {
            name: "Reports",
            route: routes.REPORTS,
            icon: <CheckCircleOutlineIcon />,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate(routes.ROOT)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      {apps().map((app) => (
        <ListItemButton onClick={() => navigate(app.route)} key={app.name}>
          <ListItemIcon>{app.icon}</ListItemIcon>
          <ListItemText primary={app.name} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Reports
      </ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last quarter" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Year-end" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const UserActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(resetAuth());
    navigate(routes.LOGIN);
  };
  return (
    <React.Fragment>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </React.Fragment>
  );
};
