/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, styled, Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useGetSelfQuery } from "../../api/user";
import { ADMIN } from "../../constants/roles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

function Breakdowns() {
  const navigate = useNavigate();
  const { data } = useGetSelfQuery();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Item onClick={() => navigate(routes.BREAKDOWNS_LIST)}>
              Create/Close/View tickets
            </Item>
            {/* <Item onClick={() => navigate(routes.BREAKDOWNS_HISTORY)}>
              Breakdowns History
            </Item> */}
            {data?.user?.role === ADMIN && (
              <Item onClick={() => navigate(routes.BREAKDOWN_SETTINGS)}>
                Breakdown Settings
              </Item>
            )}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Breakdowns;
