import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import PieGraph from "./PieGraph";
import Loader from "../../Utils/Loader/Loader";

const WorkData = ({ data }) => {
  const navigate = useNavigate();
  if (!data) return <Loader />;
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Inspections
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button
                onClick={() =>
                  navigate(
                    routes.PLANNED_INSPECTIONS.replace(":workType", "scheduled")
                  )
                }
              >
                Due in Next 30 days: &nbsp;
                <b>{data.scheduled.due_in_30_days}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button
                onClick={() =>
                  navigate(
                    routes.OVERDUE_INSPECTIONS.replace(":workType", "scheduled")
                  )
                }
              >
                Overdue: &nbsp;<b>{data.scheduled.overdue}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Maintenance
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button
                onClick={() =>
                  navigate(
                    routes.PLANNED_INSPECTIONS.replace(
                      ":workType",
                      "maintenance"
                    )
                  )
                }
              >
                Due in Next 30 days: &nbsp;
                <b>{data.maintenance.due_in_30_days}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button
                onClick={() =>
                  navigate(
                    routes.OVERDUE_INSPECTIONS.replace(
                      ":workType",
                      "maintenance"
                    )
                  )
                }
              >
                Overdue: &nbsp;<b>{data.maintenance.overdue}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Breakdowns
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.BREAKDOWNS_LIST)}>
                Major: &nbsp;
                <b>{data.breakdowns.major}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.BREAKDOWNS_LIST)}>
                Minor: &nbsp;<b>{data.breakdowns.minor}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          textAlign={"center"}
          sx={{ m: 0, p: 0 }}
        >
          Buoys
        </Typography>
        <PieGraph data={data.buoys} />
      </Grid>
    </Grid>
  );
};
export default WorkData;
