/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "../../Utils/ConfirmDialog/ConfirmDialog";
import { useDeleteBuoyMutation, useLazyGetBuoysQuery } from "../../api/buoys";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../Utils";

const RecordsTable = ({
  refreshCount,
  onDelete,
  onSelect,
  onAdditionalSelect,
  isAdmin,
}) => {
  const [getRecords, getRecordsResult] = useLazyGetBuoysQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteRecord, deleteRecordResult] = useDeleteBuoyMutation();

  React.useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      onDelete();
    }
  }, [deleteRecordResult.data]);
  React.useEffect(() => {
    getRecords({ refreshCount });
  }, [refreshCount]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const navigate = useNavigate();
  let columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              params.row.status === "available" ? "green" : "red",
            p: 1,
            borderRadius: "8px",
          }}
        >
          <Typography>
            {params.row.status === "available" ? "Available" : "Unavailable"}
          </Typography>
        </Box>
      ),
    },
    { field: "username", headerName: "Responsibility", flex: 1 },
    { field: "category", headerName: "Responsibility", flex: 1 },
    {
      field: "commissioning_date",
      headerName: `Commissioning Date`,
      renderCell: (params) => (
        <>
          {params.row.commissioning_date
            ? formatDate(params.row.commissioning_date)
            : ""}
        </>
      ),
      flex: 1,
    },
  ];

  if (isAdmin)
    columns = [
      ...columns,
      ...[
        {
          field: "details",
          flex: 1,
          headerName: "Additional Details",
          width: 100,
          renderCell: (params) => (
            <IconButton
              onClick={(e) => {
                onAdditionalSelect(params.row);
                e.stopPropagation();
              }}
            >
              <AddIcon color="secondary" />
            </IconButton>
          ),
          disableColumnMenu: true,
          sortable: false,
        },
        {
          flex: 1,
          field: "edit",
          headerName: "Edit",
          width: 100,
          renderCell: (params) => (
            <IconButton
              onClick={(e) => {
                onSelect(params.row);
                e.stopPropagation();
              }}
            >
              <EditIcon color="warning" />
            </IconButton>
          ),
          disableColumnMenu: true,
          sortable: false,
        },
        {
          flex: 1,
          field: "delete",
          headerName: "Delete",
          width: 100,
          renderCell: (params) => (
            <ConfirmDialog
              onYes={() => deleteRecord({ id: params.row.id })}
              message={"Are you sure you want to delete?"}
              icon={<DeleteIcon color="error" />}
            />
          ),
          disableColumnMenu: true,
          sortable: false,
        },
      ],
    ];

  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        rows={getRecordsResult.data?.data || []}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        onRowClick={(params) =>
          navigate(routes.BUOY_DETAILS.replace(":buoyId", params.row.id))
        }
        loading={getRecordsResult.isLoading}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </Box>
  );
};

export default RecordsTable;
