import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.data = action.payload;
      state.isOpen = true;
    },
    remove: (state) => {
      state.isOpen = false;
      state.data = null;
    },
  },
});

export const { showAlert, remove } = alertsSlice.actions;

export default alertsSlice.reducer;
