/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, LinearProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DateFormat, DateTimeFormat, formatDateTime } from "../../../Utils";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { filterByDates } from "../Util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetSparesUsageQuery } from "../../../api/spares_usage";
import DisplayTableRow from "../../../Utils/DisplayTableRow/DisplayTableRow";

const SparesReportTable = () => {
  const [from, setFrom] = useState(moment().subtract(3, "months"));
  const [to, setTo] = useState(moment());
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [row, setRow] = useState(null);

  const spares = useGetSparesUsageQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const chartContainerRef = useRef(null);

  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };

  const columns = [
    { field: "spare_name", headerName: "Spare Name", flex: 1 },

    { field: "type", headerName: "Generic/Specific", flex: 1 },
    {
      field: "category",
      headerName: "Used/Replenish",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.quantity > 0 ? "Used" : "Replenished"}</>
      ),
    },
    { field: "code", headerName: "Part Number", flex: 1 },
    {
      field: "quantity",
      headerName: "Quantity used/replenished",
      flex: 1,
      renderCell: (params) => <>{Math.abs(params.row.quantity)}</>,
    },
    {
      field: "created_at",
      headerName: "Date",
      type: "date",
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.created_at ? formatDateTime(params.row.created_at) : ""}
        </>
      ),
    },
    { field: "name", headerName: "Buoy", flex: 1 },
    { field: "username", headerName: "Department User", flex: 1 },
    {
      field: "used_typed",
      headerName: "Used In",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.breakdown_id
            ? "Breakdown"
            : params.row.periodic_inspection_id
            ? "Maintenance"
            : "NA"}
        </>
      ),
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
  ];
  const records = (spares.data?.data || []).map((x) => {
    return {
      ...x,
      created_at: x.created_at ? new Date(x.created_at) : null,
    };
  });

  useEffect(() => {
    let updatedData = filterByDates(records, from, to, "created_at");
    setFilteredRecords(updatedData);
  }, [from, to, spares.data?.data]);

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From Date"
            value={from}
            onChange={(value) => setFrom(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) => to && moment(date).isAfter(moment(to))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To Date"
            value={to}
            onChange={(value) => setTo(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) =>
              from && moment(date).isBefore(moment(from))
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={3}>
        <Button variant="outlined" onClick={downloadAsPDF}>
          Download as PDF
        </Button>
      </Grid>
      <Grid item lg={12} md={12}>
        <Box sx={{ mt: 2 }} ref={chartContainerRef}>
          <DataGrid
            rows={filteredRecords}
            columns={columns}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            loading={spares.isFetching}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Spares - ${moment().format(DateTimeFormat)}.csv`,
                },
                printOptions: {
                  fileName: `Spares - ${moment().format(DateTimeFormat)}.csv`,
                },
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={(params) => setRow(params.row)}
          />
        </Box>
        {row && (
          <DisplayTableRow
            onClose={() => setRow(null)}
            row={row}
            columns={columns}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default SparesReportTable;
