/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSaveSpareMutation, useUpdateSpareMutation } from "../../api/spares";
import { useLazyGetBuoysQuery } from "../../api/buoys";
const initialState = {
  name: "",
  required_quantity: null,
  cost_per_unit: null,
  buoy_ids: [],
  code: "",
};

const NewForm = ({ onSave, selected, onClose, type }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const [getBuoys, getBuoysResult] = useLazyGetBuoysQuery();
  const [saveRecord, saveRecordResult] = useSaveSpareMutation();
  const [updateRecord, updateRecordResult] = useUpdateSpareMutation();
  const options =
    getBuoysResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];

  const handleSubmit = () => {
    if (selected)
      updateRecord({
        id: selected.id,
        body: { ...newRecord, buoy_ids: newRecord.buoy_ids.map((x) => x.id) },
      });
    else
      saveRecord({
        body: { ...newRecord, buoy_ids: newRecord.buoy_ids.map((x) => x.id) },
        type,
      });
  };

  useEffect(() => {
    if (type === "specific") getBuoys({});
  }, []);

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord({
        name: selected.name || "",
        required_quantity: selected.required_quantity,
        cost_per_unit: selected.cost_per_unit || "",
        buoy_ids: options.filter((o) =>
          (selected.buoy_ids || []).includes(o.id)
        ),
        code: selected.code,
      });
    } else {
      setNewRecord(initialState);
    }
  }, [selected]);
  useEffect(() => {
    if (saveRecordResult.isSuccess || updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [saveRecordResult.data, updateRecordResult.data]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add New
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Save Spare</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newRecord.name}
            onChange={(e) =>
              setNewRecord({ ...newRecord, name: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            type="number"
            label="Required Quantity"
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                required_quantity: e.target.value,
              })
            }
            value={newRecord.required_quantity}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            type="number"
            label="Cost Per Unit"
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                cost_per_unit: parseInt(e.target.value)
                  ? Math.abs(e.target.value)
                  : null,
              })
            }
            value={newRecord.cost_per_unit}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Part Number"
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                code: e.target.value,
              })
            }
            value={newRecord.code}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          {type === "specific" && (
            <Autocomplete
              multiple
              value={newRecord.buoy_ids}
              sx={{ my: 3 }}
              key={newRecord.buoy_ids}
              options={options}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Applicable Buoys"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              onChange={(e, value) =>
                setNewRecord((prev) => ({ ...prev, buoy_ids: value }))
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={saveRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
