/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, IconButton, TablePagination } from "@mui/material";
import {
  useDeleteCustomerMutation,
  useLazyGetCustomersQuery,
  useMasqueradeMutation,
  useResetCustomerPasswordMutation,
} from "../../api/customers";
import Loader from "../../Utils/Loader/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateMasqueradingToken } from "../../providers/userSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../routes";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../Utils/ConfirmDialog/ConfirmDialog";
const headers = ["Name", "Email", "Role", "Edit", "", "", ""];

export default function CustomersTable({ refreshCount, onDelete, onSelect }) {
  const [pageNo, setPageNo] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [getCustomers, getCustomersResult] = useLazyGetCustomersQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteCustomer, deleteCustomerResult] = useDeleteCustomerMutation();
  const [masquerade, masqueradeResult] = useMasqueradeMutation();
  // eslint-disable-next-line no-unused-vars
  const [resetPassword, _] = useResetCustomerPasswordMutation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (deleteCustomerResult.isSuccess) {
      onDelete();
    }
  }, [deleteCustomerResult.data]);

  React.useEffect(() => {
    if (masqueradeResult.isSuccess) {
      dispatch(
        updateMasqueradingToken({
          masqueradingToken: masqueradeResult.data.access_token,
        })
      );
      window.location.href = routes.ROOT;
    }
  }, [masqueradeResult.data]);

  React.useEffect(() => {
    getCustomers({ pageSize, pageNo: pageNo + 1, refreshCount });
  }, [pageSize, pageNo, refreshCount]);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((h) => {
                return <TableCell>{h}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {getCustomersResult.isLoading && <Loader />}
            {getCustomersResult.data &&
              getCustomersResult.data.users.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => onSelect(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => masquerade({ id: row.id })}>
                      Masquerade
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => resetPassword({ id: row.id })}>
                      Reset Password
                    </Button>
                  </TableCell>
                  <TableCell>
                    <ConfirmDialog
                      onYes={() => deleteCustomer({ id: row.id })}
                      message={"Are you sure you want to delete?"}
                      loading={deleteCustomerResult.loading}
                      icon={<DeleteIcon color="error" />}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={parseInt(getCustomersResult.data?.totalCount || 0)}
        rowsPerPage={pageSize}
        page={pageNo}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
