import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Histogram from "./Histogram";
import PieGraph from "./PieGraph";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateFormat } from "../../../Utils";
import moment from "moment";
import { useGetDepartmentUsersQuery } from "../../../api/customers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useGetBreakdownsQuery } from "../../../api/breakdowns";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { useGetInspectionsByTypeQuery } from "../../../api/periodic_inspections";
import { useGetSparesUsageQuery } from "../../../api/spares_usage";
import { getAllCostItems, getFormattedSpares } from "../Util";

const CostReport = () => {
  const [interval, setInterval] = useState("monthly");
  const [from, setFrom] = useState(moment().subtract("3", "months"));
  const [to, setTo] = useState(moment());
  const [users, setUsers] = useState([]);
  const chartContainerRef = React.useRef(null);
  const navigate = useNavigate();

  const breakdowns = useGetBreakdownsQuery(
    { type: "history" },
    { refetchOnMountOrArgChange: true }
  );
  const maintenances = useGetInspectionsByTypeQuery(
    { type: "buoy_history", workType: "maintenance" },
    { refetchOnMountOrArgChange: true }
  );
  const spares = useGetSparesUsageQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const departmentUsers = useGetDepartmentUsersQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const options =
    departmentUsers?.data?.users?.map((x) => ({
      id: x.user_id,
      label: x.username,
    })) || [];
  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };

  const formattedSpares = getFormattedSpares(spares);
  const allCostItems = getAllCostItems(
    formattedSpares,
    breakdowns,
    maintenances
  );
  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item lg={12} md={12}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel>Interval</InputLabel>
              <Select
                label="Interval"
                onChange={(e) => setInterval(e.target.value)}
                value={interval}
                fullWidth
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="quarterly">Quarterly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="From Date"
                value={from}
                onChange={(value) => setFrom(value)}
                format={DateFormat}
                fullWidth
                sx={{ width: "100%" }}
                shouldDisableDate={(date) =>
                  to && moment(date).isAfter(moment(to))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="To Date"
                value={to}
                onChange={(value) => setTo(value)}
                format={DateFormat}
                fullWidth
                sx={{ width: "100%" }}
                shouldDisableDate={(date) =>
                  from && moment(date).isBefore(moment(from))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              multiple
              value={users}
              key={users}
              options={options}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Filter department users"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              onChange={(e, value) => setUsers(value)}
            />
          </Grid>
          <Grid item md={2}>
            <Button variant="contained" onClick={downloadAsPDF}>
              Download as PDF
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} ref={chartContainerRef}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Maintenance
            </Typography>
            <Histogram
              interval={interval}
              fromDate={from}
              toDate={to}
              users={users}
              data={maintenances.data?.data || []}
              dataKey={"completed_at"}
              aggKey="cost"
            />
          </Box>

          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Spares
            </Typography>
            <Histogram
              interval={interval}
              fromDate={from}
              toDate={to}
              users={users}
              data={formattedSpares}
              dataKey={"added_on"}
              aggKey="cost"
            />
          </Box>

          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Cost – user wise
            </Typography>
            <PieGraph
              fromDate={from}
              toDate={to}
              users={users}
              data={allCostItems}
              dataKey={"username"}
              dateKey={"final_date"}
              aggKey="cost"
            />
          </Box>
          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Cost – model wise
            </Typography>
            <PieGraph
              fromDate={from}
              toDate={to}
              users={users}
              data={allCostItems}
              dataKey={"model"}
              dateKey={"final_date"}
              aggKey="cost"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
          sx={{ mt: 4 }}
        >
          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Breakdowns
            </Typography>
            <Histogram
              interval={interval}
              fromDate={from}
              toDate={to}
              users={users}
              data={breakdowns.data?.data || []}
              dataKey={"repair_completed_at"}
              aggKey="cost"
            />
          </Box>

          <Box>
            <Typography variant="h6" textAlign={"center"}>
              Total
            </Typography>
            <Histogram
              interval={interval}
              fromDate={from}
              toDate={to}
              users={users}
              data={allCostItems}
              dataKey={"final_date"}
              aggKey="cost"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item lg={12} textAlign={"center"}>
        <Button
          onClick={() => navigate(routes.COST_DETAILS_REPORT)}
          variant="contained"
        >
          More Details
        </Button>
      </Grid>
    </Grid>
  );
};

export default CostReport;
