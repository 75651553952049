/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSaveKpiMutation } from "../../../api/kpi_settings";

const SetKpi = ({ onSave, row, onClose }) => {
  const [value, setValue] = useState(row.kpi_set);
  const [saveRecord, saveRecordResult] = useSaveKpiMutation();
  const [columnsType, setColumnsType] = useState(
    row.operation || "greater_than"
  );

  const handleSubmit = () => {
    saveRecord({
      body: { value, operation: columnsType },
      id: row.kpi_id,
    });
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      onSave();
      onClose();
    }
  }, [saveRecordResult.data]);

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Set/Edit KPI</DialogTitle>
        <DialogContent>
          <TextField
            label="Value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={columnsType}
              onChange={(e, value) => setColumnsType(value)}
            >
              <FormControlLabel
                value="greater_than"
                control={<Radio />}
                label="Greater than"
              />
              <FormControlLabel
                value="less_than"
                control={<Radio />}
                label="Less than"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={saveRecordResult.isLoading || !value}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetKpi;
