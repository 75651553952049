/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, TablePagination } from "@mui/material";
import Loader from "../../../Utils/Loader/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useDeleteBuoyInspectionMutation,
  useLazyGetBuoyInspectionsQuery,
} from "../../../api/buoy_inspections";
import { formatDate } from "../../../Utils";
import ConfirmDialog from "../../../Utils/ConfirmDialog/ConfirmDialog";
import { SCHEDULED } from "../../../constants/workTypes";

export default function RecordsTable({
  refreshCount,
  onDelete,
  onSelect,
  buoyId,
  workType,
}) {
  const [pageNo, setPageNo] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const headers = [
    `${workType === SCHEDULED ? "Inspection" : "Maintenance"} Name`,
    "Start Date",
    "",
    "",
  ];

  const [getRecords, getRecordsResult] = useLazyGetBuoyInspectionsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteRecord, deleteRecordResult] = useDeleteBuoyInspectionMutation();

  React.useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      onDelete();
    }
  }, [deleteRecordResult.data]);
  React.useEffect(() => {
    getRecords({
      buoyId,
      pageSize,
      pageNo: pageNo + 1,
      refreshCount,
      workType,
    });
  }, [pageSize, pageNo, refreshCount, buoyId]);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((h) => {
                return <TableCell>{h}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRecordsResult.isLoading && <Loader />}
            {getRecordsResult.data &&
              getRecordsResult.data.data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{formatDate(row.start_date)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        onSelect(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <ConfirmDialog
                      onYes={() => deleteRecord({ id: row.id })}
                      message={"Are you sure you want to delete?"}
                      icon={<DeleteIcon color="error" />}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={parseInt(getRecordsResult.data?.totalCount || 0)}
        rowsPerPage={pageSize}
        page={pageNo}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
