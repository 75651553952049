/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, styled, Paper, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import { useGetSelfQuery } from "../../api/user";
import { ADMIN } from "../../constants/roles";
import { SCHEDULED } from "../../constants/workTypes";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

function Inspections() {
  const navigate = useNavigate();
  const { data } = useGetSelfQuery();
  const { workType } = useParams();
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Item
              onClick={() =>
                navigate(
                  routes.PLANNED_INSPECTIONS.replace(":workType", workType)
                )
              }
            >
              Planned {title}
            </Item>
            <Item
              onClick={() =>
                navigate(
                  routes.OVERDUE_INSPECTIONS.replace(":workType", workType)
                )
              }
            >
              Overdue {title}
            </Item>
            {/* <Item
              onClick={() =>
                navigate(
                  routes.INSPECTIONS_HISTORY.replace(":workType", workType)
                )
              }
            >
              {title} History
            </Item> */}
            {data?.user?.role === ADMIN && (
              <>
                <Item
                  onClick={() =>
                    navigate(
                      routes.CUSTOM_INSPECTIONS.replace(":workType", workType)
                    )
                  }
                >
                  Create Custom {title}
                </Item>
                <Item
                  onClick={() =>
                    navigate(
                      routes.BUOY_INSPECTIONS.replace(":workType", workType)
                    )
                  }
                >
                  Link {title} to Buoy
                </Item>
              </>
            )}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Inspections;
