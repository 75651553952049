/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCreateNcMutation } from "../../../api/nonconformity_items";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateFormat } from "../../../Utils";
import { useGetSelfQuery } from "../../../api/user";
import { ADMIN } from "../../../constants/roles";

const initialState = {
  source: "",
  kpi_set: null,
  met_percentage: null,
  label: [],
  details: "",
  period: "",
};

const NewForm = ({ onSave }) => {
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment());
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const [saveRecord, saveRecordResult] = useCreateNcMutation();

  const user = useGetSelfQuery();
  const isAdmin = user.data?.user?.role === ADMIN;

  const handleSubmit = () => {
    const body = {
      ...newRecord,
      period: `${moment(from).format(DateFormat)} - ${moment(to).format(
        DateFormat
      )}`,
    };
    delete body.to;
    delete body.from;

    saveRecord({
      body: { items: [body] },
    });
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      onSave();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [saveRecordResult.data]);

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add New
        </Button>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Non Conformity</DialogTitle>
        <DialogContent>
          <TextField
            label="Non Conformity"
            value={newRecord.label}
            onChange={(e) =>
              setNewRecord({ ...newRecord, label: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Source"
            value={newRecord.source}
            onChange={(e) =>
              setNewRecord({ ...newRecord, source: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            type="number"
            label="Value"
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                kpi_set: parseInt(e.target.value)
                  ? Math.abs(e.target.value)
                  : null,
              })
            }
            value={newRecord.kpi_set}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            type="number"
            label="KPI"
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                met_percentage: parseInt(e.target.value)
                  ? Math.abs(e.target.value)
                  : null,
              })
            }
            value={newRecord.met_percentage}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="From Date"
                  value={from}
                  onChange={(value) => setFrom(value)}
                  format={DateFormat}
                  fullWidth
                  sx={{ width: "100%" }}
                  shouldDisableDate={(date) =>
                    to && moment(date).isAfter(moment(to))
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="To Date"
                  value={to}
                  onChange={(value) => setTo(value)}
                  format={DateFormat}
                  fullWidth
                  sx={{ width: "100%" }}
                  shouldDisableDate={(date) =>
                    from && moment(date).isBefore(moment(from))
                  }
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <TextField
            label="Details"
            value={newRecord.details}
            onChange={(e) =>
              setNewRecord({ ...newRecord, details: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={saveRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
