/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useUpdateNcMutation } from "../../../api/nonconformity_items";

const initialState = {
  root_cause: "",
  corrective_action: "",
  closing_plan_at: null,
};
const Analyze = ({ onSave, onClose, record }) => {
  const [newRecord, setNewRecord] = useState(initialState);
  const [saveRecord, saveRecordResult] = useUpdateNcMutation();

  const handleSubmit = () => {
    saveRecord({
      id: record.id,
      body: {
        ...newRecord,
        closing_plan_at: newRecord.closing_plan_at?.format(),
      },
    });
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
    }
  }, [saveRecordResult.data]);

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Analyze</DialogTitle>
        <DialogContent>
          <TextField
            label={"Root Cause"}
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                root_cause: e.target.value,
              })
            }
            value={newRecord.root_cause}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Corrective Action"
            value={newRecord.corrective_action}
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                corrective_action: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2 }}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={"Proposed corrective action completion date"}
              value={newRecord.closing_plan_at}
              onChange={(value) =>
                setNewRecord({
                  ...newRecord,
                  closing_plan_at: value,
                })
              }
              format="Do MMM YYYY"
              sx={{ width: "100%" }}
              disablePast
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              saveRecordResult.isLoading ||
              !(
                newRecord.closing_plan_at &&
                newRecord.corrective_action &&
                newRecord.root_cause
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Analyze;
