import { rootApi, requestHandler } from ".";

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSelf: builder.query({
      query: () => "v1/users/self",
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useGetSelfQuery } = userApi;
