import { useDispatch, useSelector } from "react-redux";
import AlertSnackbar from "./AlertSnackbar";
import { remove } from "../../providers/alertSlice";

const Alerts = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts);

  const onAlertClose = () => {
    dispatch(remove());
  };

  if (alerts.data) {
    return (
      <AlertSnackbar
        {...alerts.data}
        onClose={onAlertClose}
        open={alerts.isOpen}
      />
    );
  }
  return null;
};

export default Alerts;
