/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateFormat, formatEndOfDay, formatStartOfDay } from "../../../Utils";
import moment from "moment";
import { useGetDepartmentUsersQuery } from "../../../api/customers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import { useLazyGetPeMetricsQuery } from "../../../api/performance_evaluation";
import styles from "./PerformanceEvaluation.module.css";
import SetKpi from "./SetKpi";
import { useSaveKpiMutation } from "../../../api/kpi_settings";
import Loader from "../../../Utils/Loader/Loader";
import { useAddToNcListMutation } from "../../../api/nonconformity_items";
import { routes } from "../../../routes";
import { useGetSelfQuery } from "../../../api/user";
import { ADMIN } from "../../../constants/roles";

const PerformanceEvaluation = () => {
  const [from, setFrom] = useState(moment().subtract("3", "months"));
  const [to, setTo] = useState(moment());
  const [users, setUsers] = useState([]);
  const [category, setCategory] = useState(null);

  const chartContainerRef = React.useRef(null);
  const navigate = useNavigate();
  const [row, setRow] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [saveKpi, saveKpiResult] = useSaveKpiMutation();
  const [ncList, setNcList] = useState([]);
  const [addToNcList, addToNcListResult] = useAddToNcListMutation();
  const [getMetrics, getMetricsResult] = useLazyGetPeMetricsQuery();
  const user = useGetSelfQuery();
  const departmentUsers = useGetDepartmentUsersQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const options =
    departmentUsers?.data?.users?.map((x) => ({
      id: x.user_id,
      label: x.username,
    })) || [];

  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };

  useEffect(() => {
    if (saveKpiResult.isSuccess) {
      setRefreshCount((prev) => prev + 1);
    }
  }, [saveKpiResult.data]);

  useEffect(() => {
    getMetrics({
      refreshCount,
      from: formatStartOfDay(from),
      to: formatEndOfDay(to),
      users: JSON.stringify(users),
      category,
    });
  }, [from, to, category, users, refreshCount]);

  useEffect(() => {}, [from, to, category, users]);
  const handleCheckbox = (row) => {
    const index = ncList.findIndex((item) => item.kpi_id === row.kpi_id);

    if (index >= 0) {
      ncList.splice(index, 1);
    } else {
      ncList.push(row);
    }

    setNcList([...ncList]);
  };

  const addToNcListHandler = () => {
    addToNcList({
      body: {
        items: ncList.map((x) => ({
          achieved: x.achieved,
          total: x.total,
          met_percentage: x.met_percentage,
          kpi_id: x.kpi_id,
          kpi_set: x.kpi_set,
          is_met: x.is_met,
          source: "Quality management reports",
          period: `${moment(from).format(DateFormat)} - ${moment(to).format(
            DateFormat
          )}`,
          details:
            users.map((x) => x.label).join(", ") + parseInt(category) > 0
              ? ` Category: ${category}`
              : "",
        })),
      },
    });
  };

  useEffect(() => {
    if (addToNcListResult.isSuccess) {
      navigate(routes.NON_CONFORMITY);
    }
  }, [addToNcListResult.data]);

  const isAdmin = user.data?.user?.role === ADMIN;
  const renderContent = () => {
    return (getMetricsResult.data?.data || []).map((x) => {
      return (
        <Grid
          container
          spacing={3}
          key={x.kpi_id}
          className={styles.kpi}
          sx={{
            backgroundColor:
              x.workflow_state === "deleted" ? "lightgrey" : "white",
          }}
        >
          {isAdmin && (
            <Grid item md={2}>
              <Checkbox
                checked={ncList.find((n) => n.kpi_id === x.kpi_id)}
                onChange={() => handleCheckbox(x)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ position: "relative", top: "-8px" }}
              />
            </Grid>
          )}

          <Grid item md={4}>
            <Typography sx={{ pb: "24px" }}>{x.label}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography sx={{ pb: "24px" }}>
              {x.kpi_set ? (
                <Typography sx={{ color: x.is_met ? "green" : "red" }}>
                  {x.met_percentage}/{x.kpi_set}
                </Typography>
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
          {isAdmin && (
            <Grid item md={2}>
              <Button
                variant="outlined"
                onClick={() => setRow(x)}
                disabled={x.workflow_state === "deleted"}
                sx={{ position: "relative", top: "-8px" }}
              >
                Set/Edit KPI
              </Button>
            </Grid>
          )}

          {isAdmin && (
            <Grid item md={2}>
              <Button
                color={x.workflow_state === "active" ? "error" : "success"}
                variant="outlined"
                sx={{ position: "relative", top: "-8px" }}
                onClick={() =>
                  saveKpi({
                    id: x.kpi_id,
                    body: {
                      workflow_state:
                        x.workflow_state === "active" ? "deleted" : "active",
                    },
                  })
                }
              >
                {x.workflow_state === "active" ? "Disable" : "Enable"}
              </Button>
            </Grid>
          )}
        </Grid>
      );
    });
  };
  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item lg={12} md={12}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="From Date"
                value={from}
                onChange={(value) => setFrom(value)}
                format={DateFormat}
                fullWidth
                sx={{ width: "100%" }}
                shouldDisableDate={(date) =>
                  to && moment(date).isAfter(moment(to))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="To Date"
                value={to}
                onChange={(value) => setTo(value)}
                format={DateFormat}
                fullWidth
                sx={{ width: "100%" }}
                shouldDisableDate={(date) =>
                  from && moment(date).isBefore(moment(from))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              >
                {[undefined, 1, 2, 3].map((cat) => (
                  <MenuItem value={cat} key={cat}>
                    {cat ? cat : "None"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              multiple
              value={users}
              key={users}
              options={options}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Filter department users"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              onChange={(e, value) => setUsers(value)}
            />
          </Grid>
          <Grid item md={2}>
            <Button variant="contained" onClick={downloadAsPDF}>
              Download as PDF
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {!getMetricsResult.isLoading && getMetricsResult.isFetching && (
        <Grid item md={12} textAlign={"center"}>
          <Loader />
        </Grid>
      )}

      <Grid item md={12} ref={chartContainerRef} sx={{ mx: 4 }}>
        {getMetricsResult.isLoading ? <Loader /> : renderContent()}
      </Grid>
      {isAdmin && (
        <Grid item lg={12} textAlign={"center"}>
          <Button
            onClick={addToNcListHandler}
            variant="contained"
            disabled={ncList.length === 0}
          >
            Add to NC list
          </Button>
        </Grid>
      )}

      {row && (
        <SetKpi
          row={row}
          onClose={() => setRow(null)}
          onSave={() => setRefreshCount((prev) => prev + 1)}
        />
      )}
    </Grid>
  );
};

export default PerformanceEvaluation;
