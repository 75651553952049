/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";

const ConfirmDialog = ({ onYes, message, loading, icon }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpenDialog(true);
          e.stopPropagation();
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmation Alert</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onYes();
              setOpenDialog(false);
            }}
            color="primary"
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
