import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const sparesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpares: builder.query({
      query: ({ refreshCount, type, buoyId }) =>
        addParamsToUrl("v1/spares", {
          refreshCount,
          type,
          buoyId,
        }),
      onQueryStarted: requestHandler,
    }),
    saveSpare: builder.mutation({
      query: ({ body, type }) => ({
        url: addParamsToUrl("v1/spares", { type }),
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    deleteSpare: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/spares/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateSpare: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/spares/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateQuantity: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/spares/${id}/quantity`,
          method: "POST",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useLazyGetSparesQuery,
  useSaveSpareMutation,
  useDeleteSpareMutation,
  useUpdateSpareMutation,
  useGetSparesQuery,
  useUpdateQuantityMutation,
} = sparesApi;
