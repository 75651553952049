/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCompleteInspectionMutation } from "../../../api/periodic_inspections";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SparesSelection from "../../../Utils/SparesSelection/SparesSelection";
import { SCHEDULED } from "../../../constants/workTypes";
import { getPositiveValue } from "../../../Utils";

const initialState = {
  checklist: [],
  completed_at: null,
  documents: [],
  delay_reason: "",
  remarks: "",
  cost: null,
  hours_spent: 0,
};

const NewForm = ({ onSave, selected, onClose, type, title, workType }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);

  const [updateRecord, updateRecordResult] = useCompleteInspectionMutation();
  const handleSubmit = () => {
    const body = new FormData();
    for (const key of Object.keys(newRecord)) {
      switch (key) {
        case "completed_at":
          body.append(key, newRecord.completed_at?.format() || null);
          break;
        case "documents":
          if (newRecord.documents?.length) {
            for (let i = 0; i < newRecord.documents.length; i++) {
              body.append("documents", newRecord.documents[i]);
            }
          }
          break;
        case "spares":
          body.append(
            key,
            JSON.stringify(
              newRecord.spares.map((s) => ({
                id: s.id.id,
                quantity: s.quantity,
              }))
            )
          );
          break;
        default:
          body.append(key, newRecord[key]);
      }
    }
    updateRecord({ id: selected.id, body });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord((prev) => ({
        ...prev,
        checklist: selected.inspection_checklist?.data || [],
      }));
    }
  }, [selected]);

  useEffect(() => {
    if (updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [updateRecordResult.data]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          onClose();
        }}
      >
        <DialogTitle>Complete {title}</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Completed On"
              value={newRecord.completed_at}
              onChange={(value) =>
                setNewRecord((prev) => ({ ...prev, completed_at: value }))
              }
              format="Do MMM YYYY"
              sx={{ width: "100%", mt: 2 }}
            />
          </LocalizationProvider>

          <List sx={{ width: "100%" }}>
            {newRecord.checklist.map((value) => {
              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton role={undefined} dense>
                    <ListItemIcon>
                      <Checkbox edge="start" tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          {type === "overdue" && (
            <TextField
              label="Reason for delay"
              value={newRecord.delay_reason}
              onChange={(e) =>
                setNewRecord((prev) => ({
                  ...prev,
                  delay_reason: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "15px" }}
            />
          )}
          <TextField
            label="Remarks"
            rows={3}
            multiline
            value={newRecord.remarks}
            onChange={(e) =>
              setNewRecord((prev) => ({
                ...prev,
                remarks: e.target.value,
              }))
            }
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            label="Hours spent"
            type="number"
            value={newRecord.hours_spent}
            onChange={(e) =>
              setNewRecord((prev) => ({
                ...prev,
                hours_spent: getPositiveValue(e.target.value),
              }))
            }
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          {workType !== SCHEDULED && (
            <TextField
              label="Cost"
              type="number"
              value={newRecord.cost}
              onChange={(e) =>
                setNewRecord((prev) => ({
                  ...prev,
                  cost: parseInt(e.target.value)
                    ? Math.abs(e.target.value)
                    : null,
                }))
              }
              fullWidth
              style={{ marginBottom: "15px" }}
            />
          )}
          <Button variant="outlined" component="label">
            Upload Supporting documents
            <input
              type="file"
              accept=".png,.jpg"
              hidden
              multiple
              onChange={(e) =>
                setNewRecord((prev) => ({ ...prev, documents: e.target.files }))
              }
            />
          </Button>
          {newRecord.documents && (
            <Box>
              {Array.from(newRecord.documents).map((x) => (
                <Box>
                  <Typography variant="caption">{x.name} </Typography>
                </Box>
              ))}
            </Box>
          )}
          {workType !== SCHEDULED && (
            <SparesSelection
              onChange={(value) =>
                setNewRecord((prev) => ({ ...prev, spares: value }))
              }
              buoyId={selected?.buoy_id}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={updateRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
