/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import Loader from "../../../Utils/Loader/Loader";
import { useLazyGetInspectionsByTypeQuery } from "../../../api/periodic_inspections";
import { formatDate } from "../../../Utils";
import styles from "./InspectionsHistory.module.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { SCHEDULED } from "../../../constants/workTypes";

export default function RecordsTable({ buoyId, workType }) {
  const [pageNo, setPageNo] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const navigate = useNavigate();

  const [getRecords, getRecordsResult] = useLazyGetInspectionsByTypeQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const headers = [
    `${workType === SCHEDULED ? "Inspection" : "Maintenance"} Name`,
    "Scheduled At",
    "Completed On",
  ];

  React.useEffect(() => {
    getRecords({ buoyId, type: "buoy_history", workType });
  }, [buoyId]);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((h) => {
                return <TableCell>{h}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRecordsResult.isLoading && <Loader />}
            {getRecordsResult.data &&
              getRecordsResult.data.data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  className={styles.tableRow}
                  onClick={() => {
                    navigate(
                      routes.INSPECTIONS_HISTORY_DETAILS.replace(":id", row.id)
                    );
                  }}
                >
                  <TableCell>{row.inspection_name}</TableCell>
                  <TableCell>{formatDate(row.next_due_at)}</TableCell>
                  <TableCell>{formatDate(row.completed_at)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={parseInt(getRecordsResult.data?.totalCount || 0)}
        rowsPerPage={pageSize}
        page={pageNo}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
