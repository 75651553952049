/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateAdditionalBuoyMutation } from "../../api/buoys";
import moment from "moment";
import DynamicFields from "./DynamicFields";

const initialState = {
  manufacturer: "",
  model: "",
  photo: "",
  color: "",
  shape: "",
  light: "",
  dimensions: "",
  gps_location: "",
  ais: "",
  price: 0,
  currency: "",
  details: [],
};

const AdditionalForm = ({ onSave, selected, onClose }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const [updateRecord, updateRecordResult] = useUpdateAdditionalBuoyMutation();

  const handleSubmit = () => {
    const body = new FormData();
    for (const key of Object.keys(newRecord)) {
      switch (key) {
        case "commissioning_date":
          body.append(key, newRecord.commissioning_date?.format() || null);
          break;
        case "details":
          body.append(key, JSON.stringify({ data: newRecord.details }));
          break;
        default:
          body.append(key, newRecord[key]);
      }
    }
    updateRecord({ id: selected.id, body });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord({
        manufacturer: selected.manufacturer || "",
        model: selected.model || "",
        photo: selected.photo || "",
        color: selected.color || "",
        shape: selected.shape || "",
        light: selected.light || "",
        dimensions: selected.dimensions || "",
        gps_location: selected.gps_location || "",
        ais: selected.ais || "",
        price: selected.price || 0,
        currency: selected.currency || "",
        commissioning_date: selected.commissioning_date
          ? moment(selected.commissioning_date)
          : null,
        details: selected.details?.data || [],
      });
    }
  }, [selected]);
  useEffect(() => {
    if (updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [updateRecordResult.data]);

  const updateData = (key, value) => {
    setNewRecord((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Additional Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item md={6}>
              <TextField
                label="Manufacturer"
                value={newRecord.manufacturer}
                onChange={(e) => updateData("manufacturer", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Model"
                value={newRecord.model}
                onChange={(e) => updateData("model", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Color particulars"
                value={newRecord.color}
                onChange={(e) => updateData("color", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Light particulars"
                value={newRecord.light}
                onChange={(e) => updateData("light", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Dimensions"
                value={newRecord.dimensions}
                onChange={(e) => updateData("dimensions", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Coordinates"
                value={newRecord.gps_location}
                onChange={(e) => updateData("gps_location", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Currency"
                value={newRecord.currency}
                onChange={(e) => updateData("currency", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Price"
                type="number"
                value={newRecord.price}
                onChange={(e) => updateData("price", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="AIS"
                value={newRecord.ais}
                onChange={(e) => updateData("ais", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <Button variant="outlined" component="label">
                Upload Photo
                <input
                  type="file"
                  accept=".png,.jpg"
                  hidden
                  onChange={(e) => updateData("photo", e.target.files[0])}
                />
              </Button>
              {newRecord.photo && (
                <Typography variant="caption" sx={{ ml: 2 }}>
                  {newRecord.photo.name}
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              {newRecord.photo && (
                <img
                  src={
                    typeof newRecord.photo === "string"
                      ? newRecord.photo
                      : URL.createObjectURL(newRecord.photo)
                  }
                  alt="Buoy"
                  height={200}
                  width={300}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <DynamicFields
                defaultValue={selected?.details?.data}
                onChange={(fields) => updateData("details", fields)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={updateRecordResult.isLoading}
          >
            Save Buoy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdditionalForm;
