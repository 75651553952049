/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../../api/admin_settings";
const initialState = {
  breakdown_message: "",
  breakdown_rectify_message: "",
};

const BreakdownSettings = () => {
  const [newRecord, setNewRecord] = useState(initialState);
  const getSettingsResult = useGetSettingsQuery();
  const [saveRecord, saveRecordResult] = useUpdateSettingsMutation();

  const handleSubmit = () => {
    saveRecord({
      body: newRecord,
    });
  };

  useEffect(() => {
    if (getSettingsResult?.data?.data) {
      setNewRecord(getSettingsResult.data.data);
    }
  }, [getSettingsResult.data]);

  return (
    <Box sx={{ p: 5 }}>
      <TextField
        label="Breakdown message"
        value={newRecord.breakdown_message}
        onChange={(e) =>
          setNewRecord({ ...newRecord, breakdown_message: e.target.value })
        }
        sx={{ mt: 2 }}
        fullWidth
        style={{ marginBottom: "15px" }}
      />
      <TextField
        label="Breakdown rectified message"
        onChange={(e) =>
          setNewRecord({
            ...newRecord,
            breakdown_rectify_message: e.target.value,
          })
        }
        value={newRecord.breakdown_rectify_message}
        sx={{ mt: 2 }}
        fullWidth
        style={{ marginBottom: "15px" }}
      />
      <Button
        onClick={handleSubmit}
        disabled={saveRecordResult.isLoading}
        variant="contained"
      >
        Save
      </Button>
    </Box>
  );
};

export default BreakdownSettings;
