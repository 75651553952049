const routes = {
  ROOT: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CUSTOMERS: "/users",
  SIGN_UP: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  BUOYS: "/buoys",
  BUOY_DETAILS: "/buoys/:buoyId",

  INSPECTIONS: "/inspections/:workType",
  CUSTOM_INSPECTIONS: "/custom_inspections/:workType",
  BUOY_INSPECTIONS: "/buoy_inspections/:workType",
  PLANNED_INSPECTIONS: "/planned_inspections/:workType",
  OVERDUE_INSPECTIONS: "/overdue_inspections/:workType",
  INSPECTIONS_HISTORY: "/inspections_history/:workType",
  INSPECTIONS_HISTORY_DETAILS: "/inspections_history/:id",

  SPARES: "/spares",
  GENERIC_SPARES: "/spares/generic",
  SPECIFIC_SPARES: "/spares/specific",

  BREAKDOWNS: "/breakdowns",
  BREAKDOWNS_LIST: "/breakdowns/list",
  BREAKDOWNS_HISTORY: "/breakdowns/history",
  BREAKDOWN_SETTINGS: "/breakdowns/settings",
  BREAKDOWN_DETAILS: "/breakdowns/:id",

  REPORTS: "/reports",
  BREAKDOWNS_REPORT: "/reports/breakdowns",
  BREAKDOWN_DETAILS_REPORT: "/reports/breakdowns/details",
  BUOYS_REPORT: "/reports/buoys",
  INSPECTIONS_REPORT: "/reports/inspections/:workType",
  SPARES_REPORT: "/reports/spares",
  COST_REPORT: "/reports/cost",
  COST_DETAILS_REPORT: "/reports/cost/details",

  PERFORMANCE_EVALUATION: "/performance_evaluation",
  NON_CONFORMITY: "/non_conformity",

  DESIGNS: "/designs",
};

module.exports = {
  routes,
};
