import moment from "moment";
import { getRandomNumberFromArray } from "../../Utils";

export const getFormattedSpares = (spares) => {
  return (spares.data?.data || []).map((x) => {
    return {
      ...x,
      cost: Math.abs(x.cost) * Math.abs(x.quantity),
    };
  });
};
export const getAllCostItems = (formattedSpares, breakdowns, maintenances) => {
  return [
    ...formattedSpares.map((x) => ({ ...x, final_date: x.added_on })),
    ...(breakdowns.data?.data || []).map((x) => ({
      ...x,
      final_date: x.repair_completed_at,
    })),
    ...(maintenances.data?.data || []).map((x) => ({
      ...x,
      final_date: x.completed_at,
    })),
  ];
};
export const filterByUsers = (data, users) => {
  if (users && users.length) {
    const userIds = users.map((x) => x.id);

    return data.filter((x) => userIds.includes(x.department_user_id));
  }
  return data;
};

export const filterByDates = (data, from, to, dataKey) => {
  if (from && to && !moment(from).isAfter(moment(to))) {
    return data.filter(
      (x) =>
        moment(x[dataKey]).isSameOrAfter(from) &&
        moment(x[dataKey]).isSameOrBefore(to)
    );
  }
  return data;
};

export const groupByKey = (data, dataKey, aggKey = null) => {
  return Object.entries(
    data.reduce((acc, obj) => {
      acc[obj[dataKey]] =
        (acc[obj[dataKey]] || 0) + (aggKey ? parseInt(obj[aggKey]) || 0 : 1);
      return acc;
    }, {})
  ).map(([key, value]) => ({
    value,
    name: key,
    fill: getNewColor(),
  }));
};

export const groupByInterval = (data, interval, dataKey, aggKey = null) => {
  data = data.sort((a, b) =>
    moment(a[dataKey]).isBefore(moment(b[dataKey])) ? -1 : 1
  );
  if (interval === "monthly") {
    data = Object.entries(
      data.reduce((acc, obj) => {
        const monthKey = moment(obj[dataKey]).format("MMM YYYY");

        acc[monthKey] =
          (acc[monthKey] || 0) + (aggKey ? parseInt(obj[aggKey]) || 0 : 1);

        return acc;
      }, {})
    ).map(([date, frequency]) => ({
      date,
      frequency,
      fill: getNewColor(),
    }));
  } else if (interval === "yearly")
    data = Object.entries(
      data.reduce((acc, obj) => {
        const monthKey = moment(obj[dataKey]).format("YYYY");
        acc[monthKey] =
          (acc[monthKey] || 0) + (aggKey ? parseInt(obj[aggKey]) || 0 : 1);
        return acc;
      }, {})
    ).map(([date, frequency]) => ({
      date,
      frequency,
      fill: getNewColor(),
    }));
  else if (interval === "quarterly")
    data = Object.entries(
      data.reduce((acc, obj) => {
        const quarter = moment(obj[dataKey]).quarter();
        const quarterLabel = getQuarterLabel(quarter);
        const year = moment(obj[dataKey]).format("YYYY");
        const quarterKey = `${quarterLabel} ' ${year}`;
        acc[quarterKey] =
          (acc[quarterKey] || 0) + (aggKey ? parseInt(obj[aggKey]) || 0 : 1);
        return acc;
      }, {})
    ).map(([quarter, frequency]) => ({
      date: quarter,
      frequency,
      fill: getNewColor(),
    }));
  return data;
};
const getQuarterLabel = (quarter) => {
  const quarterLabels = {
    1: "Jan - Mar",
    2: "Apr - Jun",
    3: "Jul - Sep",
    4: "Oct - Dec",
  };

  return quarterLabels[quarter] || "";
};
export const getNewColor = () => getRandomNumberFromArray(colorSet);

const colorSet = [
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e74c3c",
  "#95a5a6",
  // Add more dark colors below and remove light colors
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
  "#2980b9",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#27ae60",
  "#16a085",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#f39c12",
  "#2c3e50",
  "#e74c3c",
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
  "#8e44ad",
  "#2980b9",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#27ae60",
  "#16a085",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#f39c12",
  "#2c3e50",
  "#e74c3c",
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
  "#8e44ad",
  "#2980b9",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#27ae60",
  "#16a085",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#f39c12",
  "#2c3e50",
  "#e74c3c",
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
  "#8e44ad",
  "#2980b9",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#27ae60",
  "#16a085",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#f39c12",
  "#2c3e50",
  "#e74c3c",
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
  "#8e44ad",
  "#2980b9",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#27ae60",
  "#16a085",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#f39c12",
  "#2c3e50",
  "#e74c3c",
  "#3498db",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#2ecc71",
  "#9b59b6",
];
