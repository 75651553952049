/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLazyForgotPasswordQuery } from "../../api/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

export default function ForgotPassword() {
  const [formData, setFormData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [forgotPassword, _] = useLazyForgotPasswordQuery({});
  const handleSubmit = (event) => {
    event.preventDefault();
    forgotPassword(formData);
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Enter email to receive password reset link"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={(e) => updateFormData("email", e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Get Reset Link
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={routes.LOGIN} variant="body2">
                Go back to login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
