import moment from "moment";
import queryString from "query-string";

const urlFormattingObj = { arrayFormat: "bracket" };

export function addParamsToUrl(url, paramsObj) {
  const urlObj = parseUrl(url);
  return `${urlObj.url}?${stringify({ ...urlObj.query, ...paramsObj })}`;
}
export const stringify = (params) =>
  queryString.stringify(params, urlFormattingObj);

export const parseUrl = (url) => queryString.parseUrl(url, urlFormattingObj);

export const formatDate = (val) => moment(val).format(DateFormat);

export const DateTimeFormat = "Do MMM YYYY hh:mm a";
export const DateFormat = "Do MMM YYYY";

export const formatDateTime = (val) => moment(val).format(DateTimeFormat);

export const assetUrl = (url) =>
  `${process.env.REACT_APP_API_HOST}/${url.replace("uploads", "assets")}`;

export const getPositiveValue = (value) =>
  parseInt(value) ? Math.abs(parseInt(value)) : null;

export const getRandomNumberFromArray = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

export const formatStartOfDay = (date) =>
  moment(date)?.startOf("day").format() || null;

export const formatEndOfDay = (date) =>
  moment(date)?.endOf("day").format() || null;
