/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useDeleteSpareMutation,
  useLazyGetSparesQuery,
} from "../../api/spares";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../Utils/ConfirmDialog/ConfirmDialog";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const RecordsTable = ({
  type,
  onSelect,
  onDelete,
  refreshCount,
  onUseSelect,
  onReplenishSelect,
}) => {
  const [getRecords, getRecordsResult] = useLazyGetSparesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteRecord, deleteRecordResult] = useDeleteSpareMutation();

  React.useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      onDelete();
    }
  }, [deleteRecordResult.data]);
  React.useEffect(() => {
    getRecords({ type, refreshCount });
  }, [refreshCount, type]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "code", headerName: "Code", flex: 1 },
    {
      field: "available_quantity",
      headerName: `Available Quantity`,
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            color:
              parseInt(params.row.available_quantity) >
              params.row.required_quantity
                ? "green"
                : parseInt(params.row.available_quantity) ===
                  params.row.required_quantity
                ? "orange"
                : "red",
          }}
        >
          {params.row.available_quantity || 0}
        </Typography>
      ),
    },
    {
      field: "required_quantity",
      headerName: `Required Quantity`,
      flex: 1,
    },
    {
      field: "cost_per_unit",
      headerName: "Cost",
      flex: 1,
    },
    {
      flex: 1,
      field: "use",
      headerName: "Use",
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            onUseSelect(params.row);
          }}
        >
          <RemoveIcon />
        </IconButton>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      flex: 1,
      field: "replenish",
      headerName: "Replenish",
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            onReplenishSelect(params.row);
          }}
        >
          <AddIcon />
        </IconButton>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      flex: 1,
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            onSelect(params.row);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      flex: 1,
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => (
        <ConfirmDialog
          onYes={() => deleteRecord({ id: params.row.id })}
          message={"Are you sure you want to delete?"}
          icon={<DeleteIcon color="error" />}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const records = getRecordsResult.data?.data || [];
  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        rows={records}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        loading={getRecordsResult.isFetching}
        slots={{
          loadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
};

export default RecordsTable;
