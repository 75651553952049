/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React from "react";
import { Tooltip, Legend } from "recharts";
import { PieChart, Pie } from "recharts";
import { getNewColor } from "../Reports/Util";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const PieGraph = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} onClick={() => navigate(routes.BUOYS)}>
        <PieChart width={300} height={250} sx={{ cursor: "pointer" }}>
          <Pie
            data={data.map((x) => ({ ...x, fill: getNewColor() }))}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
          ></Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Grid>
    </Grid>
  );
};

export default PieGraph;
