import { rootApi, requestHandler } from ".";

export const adminSettingsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    updateSettings: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/admin_settings/`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    getSettings: builder.query({
      query: () => `v1/admin_settings/`,
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } =
  adminSettingsApi;
