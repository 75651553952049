/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import RecordsTable from "./RecordsTable";
import { useState } from "react";
import BuoyDropdown from "../../../Utils/BuoyDropdown/BuoyDropdown";

function BreakdownsHistory() {
  const [selectedBuoy, setSelectedBuoy] = useState(null);

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Buoy BreakDown History
          </Typography>

          <BuoyDropdown onChange={(e, value) => setSelectedBuoy(value)} />

          {selectedBuoy && <RecordsTable buoyId={selectedBuoy.id} />}
        </Box>
      </Grid>
    </Grid>
  );
}

export default BreakdownsHistory;
