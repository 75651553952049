/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import NewForm from "./NewForm";
import { useState } from "react";
import RecordsTable from "./RecordsTable";
import SparesQuantityForm from "./SparesQuantityForm";

function SparesByType({ type }) {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);
  const [useSelected, setUseSelected] = useState(null);
  const [replenishSelected, setReplenishSelected] = useState(null);
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Manage {type === "generic" ? "Generic" : "Specific"} Spares
          </Typography>
          <NewForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelected(null)}
            selected={selected}
            type={type}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
            onSelect={(rec) => {
              setSelected(rec);
            }}
            onUseSelect={(rec) => {
              setUseSelected(rec);
            }}
            onReplenishSelect={(rec) => {
              setReplenishSelected(rec);
            }}
            type={type}
          />
          {(useSelected || replenishSelected) && (
            <SparesQuantityForm
              operation={useSelected ? "use" : "replenish"}
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() =>
                useSelected ? setUseSelected(null) : setReplenishSelected(null)
              }
              selected={selected}
              type={type}
              spare={useSelected || replenishSelected}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default SparesByType;
