/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box, List, ListItem, Link } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetInspectionByIdQuery } from "../../../api/periodic_inspections";
import Loader from "../../../Utils/Loader/Loader";
import { formatDate } from "../../../Utils";
import { SCHEDULED } from "../../../constants/workTypes";

function InspectionDetails() {
  const { id, workType } = useParams();
  const getResult = useGetInspectionByIdQuery({
    id,
    workType,
  });
  const data = getResult.data?.data;
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Buoy {title} History
          </Typography>
          {getResult.isLoading && <Loader />}
          {data && (
            <List>
              <ListItem>
                <b>Buoy Name:</b> &nbsp;&nbsp; {data.name}
              </ListItem>
              <ListItem>
                <b>
                  {workType === SCHEDULED ? "Inspection" : "Maintenance"} Name:
                </b>
                &nbsp;&nbsp; {data.inspection_name}
              </ListItem>
              <ListItem>
                <b>Due At :</b>&nbsp;&nbsp; {formatDate(data.next_due_at)}
              </ListItem>
              <ListItem>
                <b>Completed On:</b>&nbsp;&nbsp; {formatDate(data.completed_at)}
              </ListItem>
              <ListItem>
                <b>Checklist:</b>&nbsp;&nbsp; {data.checklist?.data?.join(", ")}
              </ListItem>
              <ListItem>
                <b>Documents:</b>&nbsp;&nbsp;{" "}
                {data.documents?.data?.map((url, i) => (
                  <Link href={url} sx={{ mr: 2 }} target="_blank">
                    Document {i + 1}
                  </Link>
                ))}
              </ListItem>
            </List>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default InspectionDetails;
