/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import RecordsTable from "./RecordsTable";
import CompletionForm from "./CompletionForm";
import { useState } from "react";
import NewForm from "./NewForm";

function BreakdownsList({ type }) {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Manage Breakdown tickets
          </Typography>
          <NewForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelected(null)}
          />
          <CompletionForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelected(null)}
            selected={selected}
            type={type}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onSelect={(record) => {
              setSelected(record);
            }}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default BreakdownsList;
