/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box, TextField, Autocomplete } from "@mui/material";
import RecordsTable from "./RecordsTable";
import { useState } from "react";
import { useGetBuoysQuery } from "../../../api/buoys";
import { useParams } from "react-router-dom";
import { SCHEDULED } from "../../../constants/workTypes";

function InspectionsHistory() {
  const [selectedBuoy, setSelectedBuoy] = useState(null);
  const { workType } = useParams();
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";

  const getBuoysResult = useGetBuoysQuery({
    pageNo: 1,
    pageSize: 10000,
  });

  const options =
    getBuoysResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Buoy {title} History
          </Typography>

          <Autocomplete
            value={selectedBuoy}
            sx={{ my: 3 }}
            key={selectedBuoy?.id}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={"Select Buoy"}
                  variant="outlined"
                  fullWidth
                />
              );
            }}
            onChange={(e, value) => setSelectedBuoy(value)}
          />

          {selectedBuoy && (
            <RecordsTable buoyId={selectedBuoy.id} workType={workType} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default InspectionsHistory;
