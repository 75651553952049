import { Autocomplete, TextField } from "@mui/material";
import { useGetBuoysQuery } from "../../api/buoys";

const BuoyDropdown = ({ buoy_id, onChange, size }) => {
  const getBuoysResult = useGetBuoysQuery({});

  const options =
    getBuoysResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];
  return (
    <Autocomplete
      value={buoy_id}
      key={buoy_id?.id}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={"Select Buoy"}
            variant="outlined"
            fullWidth
          />
        );
      }}
      size={size || "medium"}
      onChange={onChange}
    />
  );
};

export default BuoyDropdown;
