import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const dashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkMetrics: builder.query({
      query: ({ users }) =>
        addParamsToUrl("v1/dashboard/work", {
          users,
        }),
      onQueryStarted: requestHandler,
    }),
    getQualityMetrics: builder.query({
      query: () => "v1/dashboard/quality",
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useLazyGetWorkMetricsQuery, useLazyGetQualityMetricsQuery } =
  dashboardApi;
