/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import RecordsTable from "./RecordsTable";
import CompletionForm from "./CompletionForm";
import { useState } from "react";
import PostponeForm from "./PostponeForm";
import { useParams } from "react-router-dom";
import { SCHEDULED } from "../../../constants/workTypes";

function PeriodicInspections({ type }) {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);
  const [postponeSelected, setPostponeSelected] = useState(null);
  const { workType } = useParams();
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            {type === "planned" ? "Planned" : "Overdue"} {title}
          </Typography>
          <CompletionForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelected(null)}
            selected={selected}
            type={type}
            title={title}
            workType={workType}
          />
          <PostponeForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setPostponeSelected(null)}
            selected={postponeSelected}
            title={title}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onSelect={(record) => {
              setSelected(record);
            }}
            onPostPoneSelect={(record) => {
              setPostponeSelected(record);
            }}
            type={type}
            workType={workType}
            title={title}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PeriodicInspections;
