/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import styles from "./Breakdowns.module.css";
import {
  filterByDates,
  filterByUsers,
  getNewColor,
  groupByInterval,
} from "../Util";

const Histogram = ({ interval, fromDate, toDate, users, data, dataKey }) => {
  const [chartData, setChartData] = useState(
    data.map((x) => ({ ...x, fill: getNewColor() }))
  );

  useEffect(() => {
    let updatedData = filterByDates(data, fromDate, toDate, dataKey);
    updatedData = filterByUsers(updatedData, users);

    updatedData = groupByInterval(updatedData, interval, dataKey);

    setChartData(updatedData.map((x) => ({ ...x, fill: getNewColor() })));
  }, [interval, fromDate, toDate, users, data]);

  const renderCustomTooltip = ({ payload }) => {
    if (payload && payload.length > 0) {
      const dataPoint = payload[0].payload;
      return (
        <div className={styles.customTooltip}>
          <p className={styles.tooltipLabel}>{`Date: ${dataPoint.date}`}</p>
          <p
            className={styles.tooltipLabel}
          >{`Frequency: ${dataPoint.frequency}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <BarChart width={500} height={400} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            label={{
              value: "Time Period",
              position: "insideBottom",
              offset: -20,
            }}
          />
          <YAxis
            label={{ value: "Occurrences", angle: -90, position: "insideLeft" }}
            tickCount={3}
          />
          <Tooltip content={renderCustomTooltip} />
          <Bar dataKey="frequency" />
        </BarChart>
      </Grid>
    </Grid>
  );
};

export default Histogram;
