/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

const DisplayTableRow = ({ onClose, row, columns }) => {
  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {columns.map((col) => {
              return (
                <>
                  <Grid item md={4}>
                    {col.headerName}
                  </Grid>
                  <Grid item md={8}>
                    {col.renderCell ? col.renderCell({ row }) : row[col.field]}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisplayTableRow;
