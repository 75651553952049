// store.js

import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "../api";
import userReducer from "../providers/userSlice";
import alertsReducer from "../providers/alertSlice";

const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    user: userReducer,
    alerts: alertsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
});

export default store;
