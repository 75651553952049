/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetDepartmentUsersQuery } from "../../api/customers";
import {
  useLazyGetQualityMetricsQuery,
  useLazyGetWorkMetricsQuery,
} from "../../api/dashboard";
import WorkData from "./WorkData";
import QualityData from "./QualityData";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [getWorkMetrics, getWorkMetricsResult] = useLazyGetWorkMetricsQuery();
  const [getQualityMetrics, getQualityMetricsResult] =
    useLazyGetQualityMetricsQuery();

  useEffect(() => {
    getWorkMetrics({ users: JSON.stringify(users) });
  }, [users]);

  useEffect(() => {
    getQualityMetrics();
  }, []);

  const departmentUsers = useGetDepartmentUsersQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const options =
    departmentUsers?.data?.users?.map((x) => ({
      id: x.user_id,
      label: x.username,
    })) || [];

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item lg={12} md={12}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={4}>
            <Autocomplete
              multiple
              value={users}
              key={users}
              options={options}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Filter department users"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              onChange={(e, value) => setUsers(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12}>
        <WorkData data={getWorkMetricsResult.data?.data} />
      </Grid>
      <Grid item lg={12} md={12}>
        <QualityData data={getQualityMetricsResult.data?.data} />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
