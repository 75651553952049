import { rootApi, requestHandler } from ".";

export const kpiApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    saveKpi: builder.mutation({
      query: ({ id, body }) => ({
        url: `v1/kpi_settings/${id}`,
        method: "PUT",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useSaveKpiMutation } = kpiApi;
