/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import NewForm from "./NewForm";
import { useState } from "react";
import AdditionalForm from "./AdditionalForm";
import RecordsTable from "./RecordsTable";
import { useGetSelfQuery } from "../../api/user";
import { ADMIN } from "../../constants/roles";

function Buoys() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);
  const [additionalSelected, additionalSetSelected] = useState(null);
  const { data } = useGetSelfQuery();
  const isAdmin = data?.user?.role === ADMIN;
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Manage Buoys
          </Typography>
          {isAdmin && (
            <NewForm
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() => setSelected(null)}
              selected={selected}
            />
          )}

          <AdditionalForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => additionalSetSelected(null)}
            selected={additionalSelected}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
            onSelect={(rec) => {
              setSelected(rec);
            }}
            onAdditionalSelect={(rec) => {
              additionalSetSelected(rec);
            }}
            isAdmin={isAdmin}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Buoys;
