/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box, TextField, Autocomplete } from "@mui/material";
import RecordsTable from "./RecordsTable";
import NewCustomer from "./NewForm";
import { useState } from "react";
import { useGetBuoysQuery } from "../../../api/buoys";
import { useParams } from "react-router-dom";
import { SCHEDULED } from "../../../constants/workTypes";

function MapInspections() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);
  const [selectedBuoy, setSelectedBuoy] = useState(null);
  const getBuoysResult = useGetBuoysQuery({
    pageNo: 1,
    pageSize: 10000,
  });
  const { workType } = useParams();
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";

  const options =
    getBuoysResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Link {title} to Buoy
          </Typography>

          <Autocomplete
            value={selectedBuoy}
            sx={{ my: 3 }}
            key={selectedBuoy?.id}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={"Select Buoy"}
                  variant="outlined"
                  fullWidth
                />
              );
            }}
            onChange={(e, value) => setSelectedBuoy(value)}
          />
          {selectedBuoy && (
            <NewCustomer
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() => setSelected(null)}
              selected={selected}
              buoyId={selectedBuoy.id}
              workType={workType}
              title={title}
            />
          )}
          {selectedBuoy && (
            <RecordsTable
              refreshCount={refreshCount}
              onDelete={() => setRefreshCount((prev) => prev + 1)}
              onSelect={(record) => {
                setSelected(record);
              }}
              buoyId={selectedBuoy.id}
              workType={workType}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default MapInspections;
