/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { useGetSparesQuery } from "../../api/spares";

const SparesSelection = ({ onChange, buoyId }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  const addField = () => {
    setFields([...fields, { id: null, quantity: null }]);
  };

  const removeField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const updateField = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], [key]: value };
    setFields(updatedFields);
  };
  const getSparesResult = useGetSparesQuery({ buoyId });

  const options =
    getSparesResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];
  return (
    <div>
      <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
        Spare Consumption Details
      </Typography>
      {fields.map((field, index) => (
        <Grid key={index} container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={5} lg={5}>
            <Autocomplete
              value={field.id}
              key={field.id?.id}
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Select Spare"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              size={"medium"}
              onChange={(e, value) => updateField(index, "id", value)}
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <TextField
              label="Quantity"
              variant="outlined"
              size="small"
              value={field.quantity}
              type="number"
              onChange={(e) => updateField(index, "quantity", e.target.value)}
              style={{ marginLeft: "10px" }}
            />
          </Grid>
          <Grid item md={2} lg={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => removeField(index)}
              sx={{ ml: 2 }}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}
      <Button variant="outlined" color="primary" onClick={addField}>
        Add Spare
      </Button>
    </div>
  );
};

export default SparesSelection;
