/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useUpdateNcWithDocsMutation } from "../../../api/nonconformity_items";

const initialState = {
  verification_remarks: "",
  verification_documents: "",
  verified_date: null,
};

const VerificationForm = ({ onSave, onClose, record }) => {
  const [newRecord, setNewRecord] = useState(initialState);
  const [saveRecord, saveRecordResult] = useUpdateNcWithDocsMutation();

  const handleSubmit = () => {
    const body = new FormData();

    for (const key of Object.keys(newRecord)) {
      switch (key) {
        case "verified_date":
          body.append(key, newRecord.verified_date?.format() || null);
          break;
        case "verification_documents":
          if (newRecord.verification_documents?.length) {
            for (let i = 0; i < newRecord.verification_documents.length; i++) {
              body.append(
                "verification_documents",
                newRecord.verification_documents[i]
              );
            }
          }
          break;
        default:
          body.append(key, newRecord[key]);
      }
    }
    saveRecord({
      id: record.id,
      body,
    });
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
    }
  }, [saveRecordResult.data]);

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Verification</DialogTitle>
        <DialogContent>
          <TextField
            label={"Verification remarks"}
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                verification_remarks: e.target.value,
              })
            }
            value={newRecord.verification_remarks}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={"Verified date"}
              value={newRecord.verified_date}
              onChange={(value) =>
                setNewRecord({
                  ...newRecord,
                  verified_date: value,
                })
              }
              format="Do MMM YYYY"
              sx={{ width: "100%" }}
              disablePast
            />
          </LocalizationProvider>
          <Button variant="outlined" component="label" sx={{ mt: 2 }}>
            Upload Supporting documents
            <input
              type="file"
              accept=".png,.jpg,.pdf"
              hidden
              multiple
              onChange={(e) =>
                setNewRecord((prev) => ({
                  ...prev,
                  verification_documents: e.target.files,
                }))
              }
            />
          </Button>
          {newRecord.verification_documents && (
            <Box>
              {Array.from(newRecord.verification_documents).map((x) => (
                <Box>
                  <Typography variant="caption">{x.name} </Typography>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              saveRecordResult.isLoading ||
              !(newRecord.verified_date && newRecord.verification_remarks)
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerificationForm;
