/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box, styled, Paper, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetBuoyByIdQuery } from "../../../api/buoys";
import Loader from "../../../Utils/Loader/Loader";
import { formatDateTime } from "../../../Utils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

function BuoyDetails() {
  const { buoyId } = useParams();
  const buoyResult = useGetBuoyByIdQuery(
    { id: buoyId },
    { refetchOnMountOrArgChange: true }
  );
  const buoy = buoyResult.data?.data;

  const getDueText = () => {
    if (buoy.next_inspection_due?.exists) {
      if (buoy.next_inspection_due.due_in >= 0)
        return `Inspection due in ${buoy.next_inspection_due.due_in} days`;
      else
        return `Inspection overdue by ${Math.abs(
          buoy.next_inspection_due.due_in
        )} days`;
    } else return "No inspections planned";
  };
  const getMaintenanceDueText = () => {
    if (buoy.next_maintenance_due?.exists) {
      if (buoy.next_maintenance_due.due_in >= 0)
        return `Preventive maintenance due in ${buoy.next_maintenance_due.due_in} days`;
      else
        return `Preventive maintenance overdue by ${Math.abs(
          buoy.next_maintenance_due.due_in
        )} days`;
    } else return "No Preventive maintenance planned";
  };

  const getBreakdownText = () => {
    if (buoy.breakdown) {
      return (
        <Box sx={{ width: "220px" }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {buoy.breakdown.type === "minor" ? "Minor" : "Major"} Breakdown
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Since: {formatDateTime(buoy.breakdown.occurred_on)}
          </Typography>
          <Typography variant="body2">
            Defect: {buoy.breakdown.defect}
          </Typography>
        </Box>
      );
    } else return "No Breakdown maintenance";
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          {buoyResult.isLoading && <Loader />}
          {buoy && (
            <>
              <Typography variant="h5" gutterBottom>
                Buoy: {buoy.name}
              </Typography>

              <Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <Item
                    sx={{
                      backgroundColor:
                        buoy.status === "available" ? "#70e070" : "#e76767",
                    }}
                  >
                    Status:
                    <br />
                    <b>
                      {buoy.status === "available"
                        ? "Available"
                        : "Unavailable"}
                    </b>
                  </Item>
                  <Item>{getDueText()}</Item>
                  <Item>{getMaintenanceDueText()}</Item>
                  <Item>{getBreakdownText()}</Item>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default BuoyDetails;
