/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, LinearProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetBreakdownsQuery } from "../../../api/breakdowns";
import { DateFormat, DateTimeFormat, formatDateTime } from "../../../Utils";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { filterByDates, getAllCostItems, getFormattedSpares } from "../Util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetInspectionsByTypeQuery } from "../../../api/periodic_inspections";
import { useGetSparesUsageQuery } from "../../../api/spares_usage";
import DisplayTableRow from "../../../Utils/DisplayTableRow/DisplayTableRow";

const CostDetailsReport = () => {
  const [from, setFrom] = useState(moment().subtract(3, "months"));
  const [to, setTo] = useState(moment());
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [row, setRow] = useState(null);

  const breakdowns = useGetBreakdownsQuery(
    { type: "history" },
    { refetchOnMountOrArgChange: true }
  );
  const maintenances = useGetInspectionsByTypeQuery(
    { type: "buoy_history", workType: "maintenance" },
    { refetchOnMountOrArgChange: true }
  );
  const spares = useGetSparesUsageQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const formattedSpares = getFormattedSpares(spares);
  const allCostItems = getAllCostItems(
    formattedSpares,
    breakdowns,
    maintenances
  );

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const chartContainerRef = useRef(null);

  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };
  const columns = [
    { field: "name", headerName: "Buoy Name", flex: 1 },
    { field: "username", headerName: "Department User", flex: 1 },
    { field: "model", headerName: "Model", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "cost",
      headerName: "Cost",
      width: 80,
      renderCell: (params) => (
        <>{parseInt(params.row.cost) > 0 ? params.row.cost : "N/A"}</>
      ),
    },
    {
      field: "final_date",
      headerName: "Date",
      type: "date",
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.final_date ? formatDateTime(params.row.final_date) : ""}
        </>
      ),
    },
    {
      field: "type",
      headerName: "Used In",
      width: 80,
      renderCell: (params) => (
        <>
          {params.row.occurred_on
            ? "Breakdown"
            : params.row.spare_name
            ? "Spare"
            : "Maintenance"}
        </>
      ),
    },
    {
      field: "used_for",
      headerName: "Details",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.occurred_on
            ? params.row.defect
            : params.row.next_due_at
            ? params.row.inspection_name
            : ""}
        </>
      ),
    },
  ];
  const records = allCostItems.map((x) => {
    return {
      ...x,
      final_date: new Date(x.final_date),
    };
  });

  useEffect(() => {
    let updatedData = filterByDates(records, from, to, "final_date");
    setFilteredRecords(updatedData);
  }, [from, to, breakdowns.data?.data]);

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From Date"
            value={from}
            onChange={(value) => setFrom(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) => to && moment(date).isAfter(moment(to))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To Date"
            value={to}
            onChange={(value) => setTo(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) =>
              from && moment(date).isBefore(moment(from))
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={3}>
        <Button variant="outlined" onClick={downloadAsPDF}>
          Download as PDF
        </Button>
      </Grid>
      <Grid item lg={12} md={12}>
        <Box sx={{ mt: 2 }} ref={chartContainerRef}>
          <DataGrid
            rows={filteredRecords}
            columns={columns}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            loading={breakdowns.isFetching}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Cost Report - ${moment().format(
                    DateTimeFormat
                  )}.csv`,
                },
                printOptions: {
                  fileName: `Cost Report - ${moment().format(
                    DateTimeFormat
                  )}.csv`,
                },
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={(params) => setRow(params.row)}
          />
        </Box>
        {row && (
          <DisplayTableRow
            onClose={() => setRow(null)}
            row={row}
            columns={columns}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default CostDetailsReport;
