import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const customersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ pageNo, pageSize, refreshCount }) =>
        addParamsToUrl("v1/customers", { pageNo, pageSize, refreshCount }),
      onQueryStarted: requestHandler,
    }),
    getDepartmentUsers: builder.query({
      query: () => "v1/customers?type=department",
      onQueryStarted: requestHandler,
    }),
    saveCustomer: builder.mutation({
      query: (body) => ({
        url: "v1/customers",
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    deleteCustomer: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/customers/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    masquerade: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/customers/${id}/masquerade`,
          method: "POST",
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateCustomer: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/customers/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    resetCustomerPassword: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/customers/${id}/reset_password`,
          method: "PUT",
        };
      },
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useLazyGetCustomersQuery,
  useGetDepartmentUsersQuery,
  useSaveCustomerMutation,
  useDeleteCustomerMutation,
  useMasqueradeMutation,
  useUpdateCustomerMutation,
  useResetCustomerPasswordMutation,
} = customersApi;
