/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import NewForm from "./NewForm";
import { useState } from "react";
import RecordsTable from "./RecordsTable";
import AnalyzeForm from "./Analyze";
import CloseForm from "./CloseForm";
import VerificationForm from "./VerificationForm";

function NonConformity() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [analyzeSelected, setAnalyzeSelected] = useState(null);
  const [closeSelected, setCloseSelected] = useState(null);
  const [verifySelected, setVerifySelected] = useState(null);

  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Nonconformity and corrective action
          </Typography>
          <NewForm
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
            onAnalyzeSelect={(rec) => {
              setAnalyzeSelected(rec);
            }}
            onCloseSelect={(rec) => {
              setCloseSelected(rec);
            }}
            onVerifySelect={(rec) => {
              setVerifySelected(rec);
            }}
          />
          {analyzeSelected && (
            <AnalyzeForm
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() => setAnalyzeSelected(null)}
              record={analyzeSelected}
            />
          )}
          {closeSelected && (
            <CloseForm
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() => setCloseSelected(null)}
              record={closeSelected}
            />
          )}
          {verifySelected && (
            <VerificationForm
              onSave={() => {
                setRefreshCount((prev) => prev + 1);
              }}
              onClose={() => setVerifySelected(null)}
              record={verifySelected}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default NonConformity;
