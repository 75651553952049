import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    accessToken: localStorage.getItem("accessToken"),
    redirectToLogin: false,
    masqueradingToken: localStorage.getItem("masqueradingToken"),
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.data = {
        accessToken: action.payload.accessToken,
        redirectToLogin: false,
      };
      localStorage.setItem("accessToken", action.payload.accessToken);
    },
    resetAuth: (state) => {
      state.data = {
        accessToken: null,
        redirectToLogin: false,
        masqueradingToken: null,
      };
      localStorage.removeItem("accessToken");
      localStorage.removeItem("masqueradingToken");
    },
    redirectToLogin: (state) => {
      state.data.redirectToLogin = true;
    },
    updateMasqueradingToken: (state, action) => {
      state.data.masqueradingToken = action.payload.masqueradingToken;
      localStorage.setItem(
        "masqueradingToken",
        action.payload.masqueradingToken
      );
    },
    resetMasqueradingAuth: (state) => {
      state.data.masqueradingToken = null;
      localStorage.removeItem("masqueradingToken");
    },
  },
});

export const {
  updateAuth,
  resetAuth,
  redirectToLogin,
  resetMasqueradingAuth,
  updateMasqueradingToken,
} = userSlice.actions;

export default userSlice.reducer;
