/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, styled, Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

function Spares() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Item onClick={() => navigate(routes.GENERIC_SPARES)}>
              Generic Spares
            </Item>
            <Item onClick={() => navigate(routes.SPECIFIC_SPARES)}>
              Specific Spares
            </Item>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Spares;
