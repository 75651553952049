/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Link } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useDeleteNCMutation,
  useLazyGetNcListQuery,
} from "../../../api/nonconformity_items";
import { DateTimeFormat, formatDate } from "../../../Utils";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import ConfirmDialog from "../../../Utils/ConfirmDialog/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import DisplayTableRow from "../../../Utils/DisplayTableRow/DisplayTableRow";
import { useGetSelfQuery } from "../../../api/user";
import { ADMIN } from "../../../constants/roles";

const RecordsTable = ({
  type,
  refreshCount,
  onAnalyzeSelect,
  onCloseSelect,
  onVerifySelect,
  onDelete,
}) => {
  const [row, setRow] = React.useState(null);
  const [getRecords, getRecordsResult] = useLazyGetNcListQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteRecord, deleteRecordResult] = useDeleteNCMutation();
  React.useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      onDelete();
    }
  }, [deleteRecordResult.data]);
  React.useEffect(() => {
    getRecords({ type, refreshCount });
  }, [refreshCount, type]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const user = useGetSelfQuery();
  const isAdmin = user.data?.user?.role === ADMIN;

  const columns = [
    { field: "label", headerName: "Non Conformity", flex: 1 },
    { field: "source", headerName: "Source", flex: 1 },
    { field: "met_percentage", headerName: "Value", flex: 1 },
    { field: "kpi_set", headerName: "KPI", flex: 1 },
    { field: "period", headerName: "Period", flex: 1 },
    { field: "details", headerName: "Details", flex: 1 },
    {
      field: "analyze",
      headerName: `Analyze`,
      flex: 1,
      renderCell: (params) =>
        params.row.root_cause ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          isAdmin && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAnalyzeSelect(params.row);
              }}
            >
              Analyze
            </Button>
          )
        ),
    },
    {
      field: "close",
      headerName: `Close Out`,
      flex: 1,
      renderCell: (params) =>
        params.row.root_cause ? (
          params.row.closed_at ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            isAdmin && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseSelect(params.row);
                }}
              >
                Close
              </Button>
            )
          )
        ) : (
          "-"
        ),
    },
    {
      field: "closing_plan_at",
      headerName: `Proposed closing out date`,
      renderCell: (params) => (
        <>
          {params.row.closing_plan_at
            ? formatDate(params.row.closing_plan_at)
            : ""}
        </>
      ),
      flex: 1,
    },
    {
      field: "root_cause",
      headerName: `Root Cause`,
      flex: 1,
    },
    {
      field: "corrective_action",
      headerName: `Corrective Action`,
      flex: 1,
    },
    {
      field: "closed_at",
      headerName: "Actual closing out date",
      renderCell: (params) => (
        <>{params.row.closed_at ? formatDate(params.row.closed_at) : ""}</>
      ),
      flex: 1,
    },
    {
      field: "closed_remarks",
      headerName: `Closed Remarks`,
      flex: 1,
    },
    {
      flex: 1,
      field: "verification",
      headerName: "Verification of effectiveness",
      renderCell: (params) =>
        params.row.closed_at ? (
          params.row.verified_date ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            isAdmin && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onVerifySelect(params.row);
                }}
              >
                Verify
              </Button>
            )
          )
        ) : (
          "-"
        ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "verification_remarks",
      headerName: `Verification Remarks`,
      flex: 1,
    },
    {
      flex: 1,
      field: "documents",
      headerName: "Supporting documents",
      renderCell: (params) => (
        <>
          {[
            ...(params.row.closed_documents || []),
            ...(params.row.verification_documents || []),
          ].map((url, i) => (
            <Link href={url} sx={{ mr: 2 }} target="_blank">
              {i + 1}
            </Link>
          ))}
        </>
      ),
    },
    {
      flex: 1,
      field: "delete",
      headerName: "Delete",
      renderCell: (params) =>
        isAdmin && (
          <ConfirmDialog
            onYes={() => deleteRecord({ id: params.row.id })}
            message={"Are you sure you want to delete?"}
            icon={<DeleteIcon color="error" />}
          />
        ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const records = getRecordsResult.data?.data || [];
  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        rows={records}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        loading={getRecordsResult.isFetching}
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              fileName: `Buoys - ${moment().format(DateTimeFormat)}.csv`,
            },
            printOptions: {
              fileName: `Buoys - ${moment().format(DateTimeFormat)}.csv`,
            },
          },
        }}
        onRowClick={(params) => setRow(params.row)}
      />
      {row && (
        <DisplayTableRow
          onClose={() => setRow(null)}
          row={row}
          columns={columns}
        />
      )}
    </Box>
  );
};

export default RecordsTable;
