import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const performanceEvaluationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeMetrics: builder.query({
      query: ({ refreshCount, from, to, users, category }) =>
        addParamsToUrl(`v1/performance_evaluation`, {
          refreshCount,
          from,
          to,
          users,
          category,
        }),
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useGetPeMetricsQuery, useLazyGetPeMetricsQuery } =
  performanceEvaluationApi;
