/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateQuantityMutation } from "../../api/spares";
import { useGetBuoysQuery } from "../../api/buoys";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";

const initialState = {
  quantity: null,
  added_on: null,
  buoy_id: null,
  remarks: "",
};
const SparesQuantityForm = ({ onSave, onClose, type, operation, spare }) => {
  const [newRecord, setNewRecord] = useState(initialState);
  const getBuoysResult = useGetBuoysQuery({});
  const [saveRecord, saveRecordResult] = useUpdateQuantityMutation();

  const handleSubmit = () => {
    saveRecord({
      id: spare.id,
      body: {
        ...newRecord,
        added_on: newRecord.added_on?.format(),
        buoy_id: newRecord.buoy_id?.id,
        quantity:
          operation === "use" ? -newRecord.quantity : newRecord.quantity,
      },
      type,
    });
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
    }
  }, [saveRecordResult.data]);
  const options =
    getBuoysResult?.data?.data?.map((x) => ({
      id: x.id,
      label: x.name,
    })) || [];
  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>
          {operation === "use" ? "Use Spares" : "Replenish Spares"}
        </DialogTitle>
        <DialogContent>
          <TextField
            type="number"
            label={operation === "use" ? "Quantity Consumed" : "New Quantity"}
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                quantity: parseInt(e.target.value)
                  ? Math.abs(e.target.value)
                  : null,
              })
            }
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={newRecord.quantity}
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <TextField
            multiline
            rows={2}
            label="remarks"
            value={newRecord.remarks}
            onChange={(e) =>
              setNewRecord({
                ...newRecord,
                remarks: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2 }}
          />
          {operation === "use" && (
            <Autocomplete
              value={newRecord.buoy_id}
              sx={{ my: 3 }}
              key={newRecord.buoy_id?.id}
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Select Buoy"}
                    variant="outlined"
                    fullWidth
                  />
                );
              }}
              onChange={(e, value) =>
                setNewRecord({
                  ...newRecord,
                  buoy_id: value,
                })
              }
            />
          )}

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={operation === "use" ? "Date Consumed" : "Added On"}
              value={newRecord.added_on}
              onChange={(value) =>
                setNewRecord({
                  ...newRecord,
                  added_on: value,
                })
              }
              format="Do MMM YYYY"
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={saveRecordResult.isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SparesQuantityForm;
