/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  useDeleteBreakdownMutation,
  useLazyGetBreakdownsQuery,
} from "../../../api/breakdowns";
import { DateFormat, DateTimeFormat, formatDateTime } from "../../../Utils";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { filterByDates } from "../Util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DisplayTableRow from "../../../Utils/DisplayTableRow/DisplayTableRow";
import ConfirmDialog from "../../../Utils/ConfirmDialog/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const ReportBreakdownsDetails = () => {
  const [from, setFrom] = useState(moment().subtract(3, "months"));
  const [to, setTo] = useState(moment());
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [row, setRow] = useState(null);
  const [deleteRecord, deleteRecordResult] = useDeleteBreakdownMutation();

  const [getBreakdowns, breakdowns] = useLazyGetBreakdownsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  useEffect(() => {
    getBreakdowns({ type: "history" });
  }, []);
  useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      getBreakdowns({ type: "history" });
    }
  }, [deleteRecordResult.data]);

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const chartContainerRef = useRef(null);

  const downloadAsPDF = () => {
    if (!chartContainerRef.current) return;

    html2canvas(chartContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 100);
      pdf.save("charts.pdf");
    });
  };
  const columns = [
    {
      field: "type",
      headerName: "Major/Minor",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            backgroundColor: params.row.type === "minor" ? "orange" : "red",
            p: 1,
            borderRadius: "8px",
          }}
        >
          {params.row.type === "minor" ? "Minor" : "Major"}
        </Typography>
      ),
    },
    { field: "name", headerName: "Buoy Name", flex: 1 },
    { field: "model", headerName: "Model", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "username", headerName: "Department User", flex: 1 },
    { field: "defect", headerName: "Fault", flex: 1 },
    { field: "root_cause", headerName: "Root Cause", flex: 1 },
    {
      field: "occurred_on",
      headerName: "Breakdown At",
      type: "date",
      flex: 2,
      renderCell: (params) => <>{formatDateTime(params.row.occurred_on)}</>,
    },
    {
      field: "repair_started_on",
      headerName: "Repair Started At",
      type: "date",
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.repair_started_on
            ? formatDateTime(params.row.repair_started_on)
            : ""}
        </>
      ),
    },
    {
      field: "repair_completed_at",
      headerName: "Repair Completed At",
      type: "date",
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.repair_completed_at
            ? formatDateTime(params.row.repair_completed_at)
            : ""}
        </>
      ),
    },
    { field: "used_spares", headerName: "Spares", flex: 1 },
    {
      field: "cost",
      headerName: "Breakdown cost",
      width: 80,
      renderCell: (params) => (
        <>{parseInt(params.row.cost) > 0 ? params.row.cost : "N/A"}</>
      ),
    },

    {
      field: "total_cost",
      headerName: "Total Cost (Breakdown + Spares)",
      width: 80,
      renderCell: (params) => (
        <>
          {parseInt(params.row.total_cost) > 0 ? params.row.total_cost : "N/A"}
        </>
      ),
    },
    {
      flex: 1,
      field: "delete",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <ConfirmDialog
          onYes={() => deleteRecord({ id: params.row.id })}
          message={"Are you sure you want to delete?"}
          icon={<DeleteIcon color="error" />}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const records = (breakdowns.data?.data || []).map((x) => {
    return {
      ...x,
      occurred_on: new Date(x.occurred_on),
      repair_completed_at: x.repair_completed_at
        ? new Date(x.repair_completed_at)
        : null,
      repair_started_on: x.repair_started_on
        ? new Date(x.repair_started_on)
        : null,
    };
  });

  useEffect(() => {
    let updatedData = filterByDates(records, from, to, "occurred_on");
    setFilteredRecords(updatedData);
  }, [from, to, breakdowns.data?.data]);

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From Date"
            value={from}
            onChange={(value) => setFrom(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) => to && moment(date).isAfter(moment(to))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To Date"
            value={to}
            onChange={(value) => setTo(value)}
            format={DateFormat}
            fullWidth
            sx={{ width: "100%" }}
            shouldDisableDate={(date) =>
              from && moment(date).isBefore(moment(from))
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={3}>
        <Button variant="outlined" onClick={downloadAsPDF}>
          Download as PDF
        </Button>
      </Grid>
      <Grid item lg={12} md={12}>
        <Box sx={{ mt: 2 }} ref={chartContainerRef}>
          <DataGrid
            rows={filteredRecords}
            columns={columns}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            loading={breakdowns.isFetching}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Breakdowns - ${moment().format(
                    DateTimeFormat
                  )}.csv`,
                },
                printOptions: {
                  fileName: `Breakdowns - ${moment().format(
                    DateTimeFormat
                  )}.csv`,
                },
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={(params) => setRow(params.row)}
          />
        </Box>
        {row && (
          <DisplayTableRow
            onClose={() => setRow(null)}
            row={row}
            columns={columns}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default ReportBreakdownsDetails;
