// apiSlice.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { showAlert } from "../providers/alertSlice";
import { redirectToLogin } from "../providers/userSlice";

export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_HOST}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const { accessToken, masqueradingToken } = state?.user?.data;

      if (masqueradingToken || accessToken) {
        headers.set("Authorization", masqueradingToken || accessToken);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const requestHandler = async (id, { dispatch, queryFulfilled }) => {
  try {
    const { data } = await queryFulfilled;
    if (data.message)
      dispatch(
        showAlert({
          variant: "success",
          message: data.message,
        })
      );
  } catch (err) {
    try {
      const { data } = err.error;
      const errors = data.errors;

      if (errors && errors.length) {
        const error = errors[0];
        if (error.status === 401) {
          dispatch(
            showAlert({
              variant: "error",
              message: data.message,
            })
          );
          dispatch(redirectToLogin());
          return;
        }
      }

      dispatch(
        showAlert({
          variant: "error",
          message: data.message,
        })
      );
    } catch (error) {
      dispatch(
        showAlert({
          variant: "error",
          message: "Something went wrong. Please try again after sometime",
        })
      );
    }
  }
};
