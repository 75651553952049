/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tooltip, Legend } from "recharts";
import { PieChart, Pie } from "recharts";
import { filterByDates, filterByUsers, getNewColor, groupByKey } from "../Util";

const PieGraph = ({
  fromDate,
  toDate,
  users,
  data,
  dataKey,
  dateKey,
  aggKey,
}) => {
  const [chartData, setChartData] = useState(
    data.map((x) => ({ ...x, fill: getNewColor() }))
  );

  useEffect(() => {
    let updatedData = filterByDates(data, fromDate, toDate, dateKey);
    updatedData = filterByUsers(updatedData, users);
    updatedData = groupByKey(updatedData, dataKey, aggKey);

    setChartData(updatedData);
  }, [fromDate, toDate, users, data]);

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <PieChart width={300} height={400}>
          <Pie
            data={chartData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
          ></Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Grid>
    </Grid>
  );
};

export default PieGraph;
