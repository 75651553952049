/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box, List, ListItem } from "@mui/material";
import { useParams } from "react-router-dom";
import Loader from "../../../Utils/Loader/Loader";
import { formatDate } from "../../../Utils";
import { useGetBreakdownByIdQuery } from "../../../api/breakdowns";

function BreakdownDetails() {
  const { id } = useParams();
  const getResult = useGetBreakdownByIdQuery({
    id,
  });
  const data = getResult.data?.data;
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Breakdown Details
          </Typography>
          {getResult.isLoading && <Loader />}
          {data && (
            <List>
              <ListItem>
                <b>Buoy Name:</b> &nbsp;&nbsp; {data.name}
              </ListItem>
              <ListItem>
                <b>Issue:</b> &nbsp;&nbsp; {data.type}
              </ListItem>
              <ListItem>
                <b>Root Cause:</b> &nbsp;&nbsp; {data.root_cause}
              </ListItem>
              <ListItem>
                <b>Breakdown Occurred At :</b>&nbsp;&nbsp;{" "}
                {formatDate(data.occurred_on)}
              </ListItem>
              <ListItem>
                <b>Repair Started On:</b>&nbsp;&nbsp;{" "}
                {formatDate(data.repair_started_on)}
              </ListItem>
              <ListItem>
                <b>Repair Completed On:</b>&nbsp;&nbsp;{" "}
                {formatDate(data.repair_completed_at)}
              </ListItem>
            </List>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default BreakdownDetails;
