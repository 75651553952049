import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        m: 5,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
