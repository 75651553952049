/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SparesSelection from "../../../Utils/SparesSelection/SparesSelection";
import {
  useCompleteBreakdownMutation,
  useGetBreakDownMetaQuery,
} from "../../../api/breakdowns";
import { DateTimeFormat } from "../../../Utils";
import FreeSoloAutoComplete from "../../../Utils/FreeSoloAutoComplete";

const initialState = {
  repair_completed_at: null,
  repair_started_on: null,
  spares: [],
  cost: null,
  defect: "",
  root_cause: "",
};

const NewForm = ({ onSave, selected, onClose, title }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const breakdownsMeta = useGetBreakDownMetaQuery();
  const [afterMessage, setAfterMessage] = useState(null);

  const [updateRecord, updateRecordResult] = useCompleteBreakdownMutation();
  const handleSubmit = () => {
    const body = new FormData();
    for (const key of Object.keys(newRecord)) {
      switch (key) {
        case "repair_completed_at":
        case "repair_started_on":
          body.append(key, newRecord[key]?.format() || null);
          break;
        case "spares":
          body.append(
            key,
            JSON.stringify(
              newRecord.spares.map((s) => ({
                id: s.id.id,
                quantity: s.quantity,
              }))
            )
          );
          break;
        default:
          body.append(key, newRecord[key]);
      }
    }
    updateRecord({ id: selected.id, body });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord((prev) => ({
        ...prev,
        defect: selected.defect,
        root_cause: selected.root_cause,
      }));
    }
  }, [selected]);

  const resetState = () => {
    onSave();
    onClose();
    setNewRecord(initialState);
    setOpenDialog(false);
    setAfterMessage(null);
  };

  useEffect(() => {
    if (updateRecordResult.isSuccess) {
      if (selected && selected.type === "major") {
        setAfterMessage(updateRecordResult.data.message);
      } else resetState();
    }
  }, [updateRecordResult.data]);

  const renderContent = () => {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            label="Repair Started At"
            value={newRecord.repair_started_on}
            onChange={(value) =>
              setNewRecord((prev) => ({ ...prev, repair_started_on: value }))
            }
            format={DateTimeFormat}
            sx={{ width: "100%", mt: 2 }}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            label="Repair Completed At"
            value={newRecord.repair_completed_at}
            onChange={(value) =>
              setNewRecord((prev) => ({
                ...prev,
                repair_completed_at: value,
              }))
            }
            format={DateTimeFormat}
            sx={{ width: "100%", mt: 2 }}
          />
        </LocalizationProvider>

        <Box sx={{ mt: 2 }}>
          <FreeSoloAutoComplete
            options={(breakdownsMeta.data?.data?.defects || []).map((x) => ({
              id: x,
              title: x,
            }))}
            label={"Defect"}
            onChange={(value) =>
              setNewRecord((prev) => ({
                ...prev,
                defect: value?.id,
              }))
            }
            defaultValue={{ id: newRecord.defect, title: newRecord.defect }}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <FreeSoloAutoComplete
            options={(breakdownsMeta.data?.data?.root_causes || []).map(
              (x) => ({ id: x, title: x })
            )}
            label={"Root Cause"}
            onChange={(value) =>
              setNewRecord((prev) => ({
                ...prev,
                root_cause: value?.id,
              }))
            }
            defaultValue={{
              id: newRecord.root_cause,
              title: newRecord.root_cause,
            }}
          />
        </Box>

        <TextField
          label="Cost"
          value={newRecord.cost}
          onChange={(e) =>
            setNewRecord((prev) => ({
              ...prev,
              cost: parseInt(e.target.value) ? Math.abs(e.target.value) : null,
            }))
          }
          fullWidth
          sx={{ mt: 2 }}
          type="number"
        />
        <SparesSelection
          onChange={(value) =>
            setNewRecord((prev) => ({ ...prev, spares: value }))
          }
          buoyId={selected?.buoy_id}
        />
      </>
    );
  };
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          onClose();
        }}
      >
        <DialogTitle>Complete {title}</DialogTitle>
        <DialogContent>
          {afterMessage ? (
            <Typography variant="subtitle1">{afterMessage}</Typography>
          ) : (
            renderContent()
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
              setNewRecord(initialState);
              if (afterMessage) resetState();
            }}
          >
            Close
          </Button>
          {!afterMessage && (
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={
                updateRecordResult.isLoading ||
                !newRecord.repair_completed_at ||
                !newRecord.repair_started_on ||
                !newRecord.cost ||
                !newRecord.defect ||
                !newRecord.root_cause
              }
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
