import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Loader from "../../Utils/Loader/Loader";

const QualityData = ({ data }) => {
  const navigate = useNavigate();
  if (!data) return <Loader />;
  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Non-conformities : Corrective action
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.NON_CONFORMITY)}>
                Due in Next 30 days: &nbsp;
                <b>{data.due_in_30_days}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.NON_CONFORMITY)}>
                Overdue: &nbsp;<b>{data.overdue}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Analysis
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.NON_CONFORMITY)}>
                No. of non-conformities pending: &nbsp;
                <b>{data.non_analyzed}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Spares
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.GENERIC_SPARES)}>
                Generic - Low Stock: &nbsp;
                <b>{data.spares.generic.low}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.GENERIC_SPARES)}>
                Generic - Attention Required: &nbsp;
                <b>{data.spares.generic.attention}</b>
              </Button>
            </Typography>

            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.SPECIFIC_SPARES)}>
                Specific - Low Stock: &nbsp;
                <b>{data.spares.specific.low}</b>
              </Button>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Button onClick={() => navigate(routes.SPECIFIC_SPARES)}>
                Specific - Attention Required: &nbsp;
                <b>{data.spares.specific.attention}</b>
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default QualityData;
