import { rootApi, requestHandler } from ".";

export const rolesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => "v1/roles",
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useGetRolesQuery } = rolesApi;
