import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const kpiApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addToNcList: builder.mutation({
      query: ({ body }) => ({
        url: `v1/nonconformity_items/`,
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    getNcList: builder.query({
      query: ({ refreshCount }) =>
        addParamsToUrl("v1/nonconformity_items", {
          refreshCount,
        }),
      onQueryStarted: requestHandler,
    }),
    updateNc: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/nonconformity_items/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    createNc: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/nonconformity_items`,
          method: "POST",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateNcWithDocs: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/nonconformity_items/${id}/docs`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    deleteNC: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/nonconformity_items/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useAddToNcListMutation,
  useLazyGetNcListQuery,
  useUpdateNcMutation,
  useUpdateNcWithDocsMutation,
  useCreateNcMutation,
  useDeleteNCMutation,
} = kpiApi;
