import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from "./components/SignIn/SignIn";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import AccessTokenHandler from "./components/LoginHandler/AccessTokenHandler";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import Customers from "./components/Customers/Customers";
import SignUp from "./components/Signup/SignUp";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Buoys from "./components/Buoys/Buoys";
import Inspections from "./components/Inspections/Inspections";
import MapInspections from "./components/Inspections/MapInspections/MapInspections";
import BuoyDetails from "./components/Buoys/BuoyDetails/BuoyDetails";
import CustomInspections from "./components/Inspections/CustomInspections/CustomInspections";
import PeriodicInspections from "./components/Inspections/PeriodicInspections/PeriodicInspections";
import InspectionsHistory from "./components/Inspections/InspectionsHistory/InspectionsHistory";
import InspectionDetails from "./components/Inspections/InspectionsHistory/InspectionDetails";
import Spares from "./components/Spares/SparesHome";
import SparesByType from "./components/Spares/SparesByType";
import Breakdowns from "./components/Breakdowns/Breakdowns";
import BreakdownsList from "./components/Breakdowns/BreakdownsList/BreakdownsList";
import BreakdownsHistory from "./components/Breakdowns/BreakdownsHistory/BreakdownsHistory";
import BreakdownSettings from "./components/Breakdowns/BreakdownSettings/BreakdownSettings";
import BreakdownDetails from "./components/Breakdowns/BreakdownsHistory/BreakdownDetails";
import Designs from "./components/Designs/Designs";
import Reports from "./components/Reports/Reports";
import BreakdownsReport from "./components/Reports/Breakdowns/Breakdowns";
import ReportBreakdownsDetails from "./components/Reports/Breakdowns/Details";
import BuoysReportsTable from "./components/Reports/Buoys/BuoysTable";
import InspectionsReportTable from "./components/Reports/Inspections/InspectionsTable";
import SparesReportTable from "./components/Reports/Spares/SparesTable";
import CostReport from "./components/Reports/Cost/Cost";
import CostDetailsReport from "./components/Reports/Cost/Details";
import PerformanceEvaluation from "./components/Reports/PerformanceEvaluation/PerformanceEvaluation";
import NonConformity from "./components/Reports/NonConformity/NonConformity";
import Home from "./components/Home/Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LOGIN} element={<SignIn />} />
        <Route path={routes.SIGN_UP} element={<SignUp />} />
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />

        <Route
          path={routes.ROOT}
          element={<AccessTokenHandler component={<BaseLayout />} />}
        >
          <Route path={routes.ROOT} element={<Home />} />

          <Route path={routes.DASHBOARD} element={<Dashboard />} />
          <Route path={routes.CUSTOMERS} element={<Customers />} />

          {/* buoy routes */}
          <Route path={routes.BUOYS} element={<Buoys />} />
          <Route path={routes.BUOY_DETAILS} element={<BuoyDetails />} />

          {/* inspection routes */}
          <Route path={routes.INSPECTIONS} element={<Inspections />} />
          <Route
            path={routes.CUSTOM_INSPECTIONS}
            element={<CustomInspections />}
          />
          <Route path={routes.BUOY_INSPECTIONS} element={<MapInspections />} />
          <Route
            path={routes.PLANNED_INSPECTIONS}
            element={<PeriodicInspections type={"planned"} />}
          />
          <Route
            path={routes.OVERDUE_INSPECTIONS}
            element={<PeriodicInspections type={"overdue"} />}
          />
          <Route
            path={routes.INSPECTIONS_HISTORY}
            element={<InspectionsHistory />}
          />
          <Route
            path={routes.INSPECTIONS_HISTORY_DETAILS}
            element={<InspectionDetails />}
          />

          {/* spares routes */}
          <Route path={routes.SPARES} element={<Spares />} />
          <Route
            path={routes.GENERIC_SPARES}
            element={<SparesByType type={"generic"} />}
          />
          <Route
            path={routes.SPECIFIC_SPARES}
            element={<SparesByType type={"specific"} />}
          />

          {/* breakdown routes */}
          <Route path={routes.BREAKDOWNS} element={<Breakdowns />} />
          <Route path={routes.BREAKDOWNS_LIST} element={<BreakdownsList />} />
          <Route
            path={routes.BREAKDOWNS_HISTORY}
            element={<BreakdownsHistory />}
          />
          <Route
            path={routes.BREAKDOWN_SETTINGS}
            element={<BreakdownSettings />}
          />
          <Route
            path={routes.BREAKDOWN_DETAILS}
            element={<BreakdownDetails />}
          />

          {/* Report routes */}
          <Route path={routes.REPORTS} element={<Reports />} />
          <Route
            path={routes.BREAKDOWNS_REPORT}
            element={<BreakdownsReport />}
          />
          <Route
            path={routes.BREAKDOWN_DETAILS_REPORT}
            element={<ReportBreakdownsDetails />}
          />
          <Route path={routes.BUOYS_REPORT} element={<BuoysReportsTable />} />
          <Route
            path={routes.INSPECTIONS_REPORT}
            element={<InspectionsReportTable />}
          />
          <Route path={routes.SPARES_REPORT} element={<SparesReportTable />} />
          <Route path={routes.COST_REPORT} element={<CostReport />} />
          <Route
            path={routes.COST_DETAILS_REPORT}
            element={<CostDetailsReport />}
          />

          <Route
            path={routes.PERFORMANCE_EVALUATION}
            element={<PerformanceEvaluation />}
          />
          <Route path={routes.NON_CONFORMITY} element={<NonConformity />} />
          <Route path={routes.DESIGNS} element={<Designs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
