import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const inspectionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getInspections: builder.query({
      query: ({ pageNo, pageSize, refreshCount, workType }) =>
        addParamsToUrl("v1/inspections", {
          pageNo,
          pageSize,
          refreshCount,
          work_type: workType,
        }),
      onQueryStarted: requestHandler,
    }),
    saveInspection: builder.mutation({
      query: ({ body, workType }) => ({
        url: addParamsToUrl("v1/inspections", { work_type: workType }),
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    deleteInspection: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/inspections/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateInspection: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/inspections/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useLazyGetInspectionsQuery,
  useSaveInspectionMutation,
  useDeleteInspectionMutation,
  useUpdateInspectionMutation,
  useGetInspectionsQuery,
} = inspectionsApi;
