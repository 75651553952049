/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  useGetBreakDownMetaQuery,
  useSaveBreakdownMutation,
} from "../../../api/breakdowns";
import BuoyDropdown from "../../../Utils/BuoyDropdown/BuoyDropdown";
import FreeSoloAutoComplete from "../../../Utils/FreeSoloAutoComplete";
import { DateTimeFormat } from "../../../Utils";

const initialState = {
  buoy_id: null,
  type: null,
  defect: "",
  root_cause: "",
  occurred_on: null,
  cost: 0,
  currency: "",
  files: [],
  remarks: "",
};

const NewForm = ({ onSave, onClose }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const [saveRecord, saveRecordResult] = useSaveBreakdownMutation();
  const breakdownsMeta = useGetBreakDownMetaQuery();
  const [afterMessage, setAfterMessage] = useState(null);
  const handleSubmit = () => {
    const body = new FormData();
    for (const key of Object.keys(newRecord)) {
      switch (key) {
        case "occurred_on":
          body.append(key, newRecord.occurred_on?.format() || null);
          break;
        case "files":
          if (newRecord.files?.length) {
            for (let i = 0; i < newRecord.files.length; i++) {
              body.append("files", newRecord.files[i]);
            }
          }
          break;
        case "buoy_id":
          body.append(key, newRecord.buoy_id?.id || null);
          break;
        default:
          body.append(key, newRecord[key]);
      }
    }
    saveRecord({ body });
  };

  const resetState = () => {
    onSave();
    onClose();
    setNewRecord(initialState);
    setOpenDialog(false);
    setAfterMessage(null);
  };

  useEffect(() => {
    if (saveRecordResult.isSuccess) {
      if (newRecord.type === "major") {
        setAfterMessage(saveRecordResult.data.message);
      } else resetState();
    }
  }, [saveRecordResult.data]);

  const updateData = (key, value) => {
    setNewRecord((prev) => ({ ...prev, [key]: value }));
  };

  const renderContent = () => {
    return (
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={6}>
          <BuoyDropdown
            buoy_id={newRecord.buoy_id}
            onChange={(e, value) => updateData("buoy_id", value)}
          />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              onChange={(e) => updateData("type", e.target.value)}
              value={newRecord.type}
              fullWidth
            >
              <MenuItem value={"minor"}>Minor</MenuItem>
              <MenuItem value={"major"}>Major</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FreeSoloAutoComplete
            options={(breakdownsMeta.data?.data?.defects || []).map((x) => ({
              id: x,
              title: x,
            }))}
            label={"Defect"}
            onChange={(value) => {
              console.log("value", value);
              updateData("defect", value?.id);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FreeSoloAutoComplete
            options={(breakdownsMeta.data?.data?.root_causes || []).map(
              (x) => ({ id: x, title: x })
            )}
            label={"Root Cause"}
            onChange={(value) => updateData("root_cause", value?.id)}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            multiline
            rows={3}
            label="remarks"
            value={newRecord.remarks}
            onChange={(e) => updateData("remarks", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Breakdown Time"
              value={newRecord.occurred_on}
              onChange={(value) => updateData("occurred_on", value)}
              format={DateTimeFormat}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={12}>
          <Button variant="outlined" component="label">
            Upload Files
            <input
              type="file"
              accept=".png,.jpg"
              hidden
              multiple
              onChange={(e) => updateData("files", e.target.files)}
            />
          </Button>
          {newRecord.files && (
            <Box>
              {Array.from(newRecord.files).map((x) => (
                <Box>
                  <Typography variant="caption">{x.name} </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Raise New Ticket
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Raise Ticket</DialogTitle>
        <DialogContent>
          {afterMessage ? (
            <Typography variant="subtitle1">{afterMessage}</Typography>
          ) : (
            renderContent()
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
              if (afterMessage) resetState();
            }}
          >
            Close
          </Button>
          {!afterMessage && (
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={saveRecordResult.isLoading}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
