/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import { formatDateTime } from "../../../Utils";
import LinearProgress from "@mui/material/LinearProgress";
import ConfirmDialog from "../../../Utils/ConfirmDialog/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteBreakdownMutation,
  useLazyGetBreakdownsQuery,
} from "../../../api/breakdowns";

const RecordsTable = ({ refreshCount, onSelect, onDelete }) => {
  const [getRecords, getRecordsResult] = useLazyGetBreakdownsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [deleteRecord, deleteRecordResult] = useDeleteBreakdownMutation();

  React.useEffect(() => {
    getRecords({ refreshCount, type: "active" });
  }, [refreshCount]);
  React.useEffect(() => {
    if (deleteRecordResult.isSuccess) {
      onDelete();
    }
  }, [deleteRecordResult.data]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const columns = [
    { field: "name", headerName: "Buoy Name", flex: 1 },
    {
      field: "type",
      headerName: "Major/Minor breakdown",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            backgroundColor: params.row.type === "minor" ? "orange" : "red",
            p: 1,
            borderRadius: "8px",
          }}
        >
          {params.row.type === "minor" ? "Minor" : "Major"}
        </Typography>
      ),
    },
    {
      field: "occurred_on",
      headerName: "Breakdown since",
      type: "date",
      flex: 2,
      renderCell: (params) => <>{formatDateTime(params.row.occurred_on)}</>,
    },
    { field: "defect", headerName: "Defect", flex: 1 },
    { field: "root_cause", headerName: "Root Cause", flex: 1 },
    {
      flex: 1,
      field: "close",
      headerName: "",
      renderCell: (params) => (
        <Button onClick={() => onSelect(params.row)}>Complete</Button>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      flex: 1,
      field: "delete",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <ConfirmDialog
          onYes={() => deleteRecord({ id: params.row.id })}
          message={"Are you sure you want to delete?"}
          icon={<DeleteIcon color="error" />}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const records = (getRecordsResult.data?.data || []).map((x) => {
    return {
      ...x,
      occurred_on: new Date(x.occurred_on),
    };
  });
  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        rows={records}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        loading={getRecordsResult.isFetching}
        slots={{
          loadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
};

export default RecordsTable;
