/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, styled, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

function Reports() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} textAlign={"center"} sx={{ mt: 3 }}>
        <Typography variant="h5">Reports</Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box sx={{ p: 5 }}>
          <Stack direction={{ xs: "column", sm: "column" }} spacing={{ md: 4 }}>
            <Typography variant="h6">Statistics</Typography>
            <Item onClick={() => navigate(routes.BUOYS_REPORT)}>Buoys</Item>
            <Item
              onClick={() =>
                navigate(
                  routes.INSPECTIONS_REPORT.replace(":workType", "scheduled")
                )
              }
            >
              Inspections
            </Item>
            <Item
              onClick={() =>
                navigate(
                  routes.INSPECTIONS_REPORT.replace(":workType", "maintenance")
                )
              }
            >
              Maintenance
            </Item>
            <Item onClick={() => navigate(routes.BREAKDOWNS_REPORT)}>
              Breakdowns
            </Item>
            <Item onClick={() => navigate(routes.SPARES_REPORT)}>Spares</Item>
            <Item onClick={() => navigate(routes.COST_REPORT)}>Cost</Item>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box sx={{ p: 5 }}>
          <Stack direction={{ xs: "column", sm: "column" }} spacing={{ md: 4 }}>
            <Typography variant="h6">Quality management Reports</Typography>
            <Item onClick={() => navigate(routes.PERFORMANCE_EVALUATION)}>
              Performance Evaluation
            </Item>
            <Item onClick={() => navigate(routes.NON_CONFORMITY)}>
              Nonconformity and corrective action
            </Item>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Reports;
