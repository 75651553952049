/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSaveBuoyMutation, useUpdateBuoyMutation } from "../../api/buoys";
import { useGetDepartmentUsersQuery } from "../../api/customers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { formatStartOfDay } from "../../Utils";
const initialState = {
  name: "",
  department_user_id: "",
  status: "",
  commissioning_date: null,
  category: "",
};

const NewForm = ({ onSave, selected, onClose }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRecord, setNewRecord] = useState(initialState);
  const users = useGetDepartmentUsersQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [saveRecord, saveRecordResult] = useSaveBuoyMutation();
  const [updateRecord, updateRecordResult] = useUpdateBuoyMutation();

  const handleSubmit = () => {
    if (selected)
      updateRecord({
        id: selected.id,
        body: {
          ...newRecord,
          commissioning_date: formatStartOfDay(newRecord.commissioning_date),
        },
      });
    else
      saveRecord({
        ...newRecord,
        commissioning_date: formatStartOfDay(newRecord.commissioning_date),
      });
  };

  useEffect(() => {
    if (selected) {
      setOpenDialog(true);
      setNewRecord({
        name: selected.name || "",
        department_user_id: selected.department_user_id || "",
        status: selected.status || "",
        commissioning_date: selected.commissioning_date
          ? moment(selected.commissioning_date)
          : null,
        category: selected.category || "",
      });
    } else {
      setNewRecord(initialState);
    }
  }, [selected]);
  useEffect(() => {
    if (saveRecordResult.isSuccess || updateRecordResult.isSuccess) {
      onSave();
      onClose();
      setNewRecord(initialState);
      setOpenDialog(false);
    }
  }, [saveRecordResult.data, updateRecordResult.data]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add New Buoy
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Save Buoy</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newRecord.name}
            onChange={(e) =>
              setNewRecord({ ...newRecord, name: e.target.value })
            }
            sx={{ mt: 2 }}
            fullWidth
            style={{ marginBottom: "15px" }}
          />
          <FormControl fullWidth>
            <InputLabel>Department User</InputLabel>
            <Select
              label="Department User"
              onChange={(e) =>
                setNewRecord({
                  ...newRecord,
                  department_user_id: e.target.value,
                })
              }
              value={newRecord.department_user_id}
              sx={{ mb: 2 }}
            >
              {users.data &&
                users.data.users.map((user) => (
                  <MenuItem value={user.user_id} key={user.user_id}>
                    {user.username} ({user.email})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              onChange={(e) =>
                setNewRecord({
                  ...newRecord,
                  category: e.target.value,
                })
              }
              value={newRecord.category}
              sx={{ mb: 2 }}
            >
              {[1, 2, 3].map((x) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Commissioning Date"
              value={newRecord.commissioning_date}
              onChange={(value) =>
                setNewRecord({
                  ...newRecord,
                  commissioning_date: value,
                })
              }
              format="Do MMM YYYY"
              fullWidth
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>

          {!selected?.id && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                onChange={(e) =>
                  setNewRecord({
                    ...newRecord,
                    status: e.target.value,
                  })
                }
                value={newRecord.status}
              >
                {["available", "unavailable"].map((s, i) => (
                  <MenuItem value={s} key={i}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              saveRecordResult.isLoading ||
              !(
                newRecord.name &&
                newRecord.department_user_id &&
                newRecord.category &&
                newRecord.commissioning_date &&
                newRecord.status
              )
            }
            variant="contained"
          >
            Save Buoy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewForm;
