/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const AccessTokenHandler = ({ component }) => {
  const { redirectToLogin } = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectToLogin) {
      navigate(routes.LOGIN);
    }
  }, [redirectToLogin]);
  return <>{component}</>;
};

export default AccessTokenHandler;
