/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import { useLazyGetInspectionsByTypeQuery } from "../../../api/periodic_inspections";
import { formatDate } from "../../../Utils";
import LinearProgress from "@mui/material/LinearProgress";
import { SCHEDULED } from "../../../constants/workTypes";

const RecordsTable = ({
  refreshCount,
  onSelect,
  type,
  onPostPoneSelect,
  workType,
}) => {
  const [getRecords, getRecordsResult] = useLazyGetInspectionsByTypeQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  React.useEffect(() => {
    getRecords({ refreshCount, type, workType });
  }, [refreshCount]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const columns = [
    { field: "name", headerName: "Buoy Name", flex: 1 },
    {
      field: "inspection_name",
      headerName: `${
        workType === SCHEDULED ? "Inspection" : "Maintenance"
      } Name`,
      flex: 1,
    },
    {
      field: "next_due_at",
      headerName: "Due Date",
      flex: 1,
      type: "date",
      renderCell: (params) => <>{formatDate(params.row.next_due_at)}</>,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1,
      renderCell: (params) => <>{params.row.frequency} Months</>,
    },
    {
      flex: 1,
      field: "mark",
      headerName: "",
      renderCell: (params) => (
        <Button onClick={() => onSelect(params.row)}>Mark as Done</Button>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      flex: 1,
      field: "edit",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => onPostPoneSelect(params.row)}>PostPone</Button>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const records = (getRecordsResult.data?.data || []).map((x) => {
    return {
      ...x,
      next_due_at: new Date(x.next_due_at),
    };
  });
  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        rows={records}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        loading={getRecordsResult.isFetching}
        slots={{
          loadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
};

export default RecordsTable;
