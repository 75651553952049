import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const buoyInspectionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    completeInspection: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/periodic_inspections/${id}/complete`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    postponeInspection: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/periodic_inspections/${id}/postpone`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    getInspectionsByType: builder.query({
      query: ({ refreshCount, type, buoyId, workType }) =>
        addParamsToUrl(`v1/periodic_inspections`, {
          refreshCount,
          buoyId,
          type,
          work_type: workType,
        }),
      onQueryStarted: requestHandler,
    }),
    getInspectionById: builder.query({
      query: ({ id }) => `v1/periodic_inspections/${id}`,
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useCompleteInspectionMutation,
  useLazyGetInspectionsByTypeQuery,
  useGetInspectionsByTypeQuery,
  usePostponeInspectionMutation,
  useGetInspectionByIdQuery,
} = buoyInspectionsApi;
