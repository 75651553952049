/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch } from "react-redux";
import { useResetPasswordMutation } from "../../api/auth";
import { useEffect, useState } from "react";
import { updateAuth } from "../../providers/userSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import { showAlert } from "../../providers/alertSlice";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation({});
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirm_password) {
      dispatch(
        showAlert({
          variant: "error",
          message: "Passwords are not matching.",
        })
      );
      return;
    }
    const payload = { ...formData, reset_token: searchParams.get("token") };
    delete payload.confirm_password;
    resetPassword(payload);
  };

  useEffect(() => {
    if (resetPasswordResult.isSuccess && resetPasswordResult.data) {
      dispatch(
        updateAuth({
          accessToken: resetPasswordResult.data.accessToken,
        })
      );
      navigate(routes.ROOT);
    }
  }, [resetPasswordResult.isSuccess]);

  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={(e) => updateFormData("password", e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.confirm_password}
            onChange={(e) => updateFormData("confirm_password", e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !(formData.password && formData.confirm_password) ||
              resetPasswordResult.isLoading
            }
          >
            Update Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={routes.LOGIN} variant="body2">
                Go back to login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
