import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const buoyInspectionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBuoyInspections: builder.query({
      query: ({ buoyId, pageNo, pageSize, refreshCount, workType }) =>
        addParamsToUrl(`v1/buoys/${buoyId}/inspections`, {
          pageNo,
          pageSize,
          refreshCount,
          work_type: workType,
        }),
      onQueryStarted: requestHandler,
    }),
    saveBuoyInspection: builder.mutation({
      query: ({ buoyId, body, workType }) => {
        console.log("body", body);
        return {
          url: addParamsToUrl(`v1/buoys/${buoyId}/inspections`, {
            work_type: workType,
          }),
          method: "POST",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    deleteBuoyInspection: builder.mutation({
      query: ({ buoyId, id }) => {
        return {
          url: `v1/buoys/${buoyId}/inspections/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateBuoyInspection: builder.mutation({
      query: ({ id, buoyId, body }) => {
        return {
          url: `v1/buoys/${buoyId}/inspections/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useLazyGetBuoyInspectionsQuery,
  useSaveBuoyInspectionMutation,
  useDeleteBuoyInspectionMutation,
  useUpdateBuoyInspectionMutation,
} = buoyInspectionsApi;
