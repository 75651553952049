/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const DynamicFields = ({ onChange, defaultValue }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    onChange(fields);
  }, [fields]);
  useEffect(() => {
    setFields(defaultValue ? defaultValue : [{ key: "", value: "" }]);
  }, [defaultValue]);
  const addField = () => {
    setFields([...fields, { key: "", value: "" }]);
  };

  const removeField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const updateField = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = { key, value };
    setFields(updatedFields);
  };

  return (
    <div>
      <Typography variant="h6" sx={{ mb: 2 }}>
        More Details
      </Typography>
      {fields.map((field, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <TextField
            label="Key"
            variant="outlined"
            size="small"
            value={field.key}
            onChange={(e) => updateField(index, e.target.value, field.value)}
          />
          <TextField
            label="Value"
            variant="outlined"
            size="small"
            value={field.value}
            onChange={(e) => updateField(index, field.key, e.target.value)}
            style={{ marginLeft: "10px" }}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => removeField(index)}
            sx={{ ml: 2 }}
          >
            Remove
          </Button>
        </div>
      ))}
      <Button variant="outlined" color="primary" onClick={addField}>
        Add Field
      </Button>
      {/* You can use the 'fields' state as needed in your application */}
    </div>
  );
};

export default DynamicFields;
