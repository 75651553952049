/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid, Box } from "@mui/material";
import NewCustomer from "./NewForm";
import { useState } from "react";
import RecordsTable from "./RecordsTable";
import { useParams } from "react-router-dom";
import { SCHEDULED } from "../../../constants/workTypes";

function CustomInspections() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [selected, setSelected] = useState(null);
  const { workType } = useParams();
  const title = workType === SCHEDULED ? "Inspections" : "Maintenance";
  return (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Box sx={{ p: 5 }}>
          <Typography variant="h5" gutterBottom>
            Manage {title}
          </Typography>
          <NewCustomer
            onSave={() => {
              setRefreshCount((prev) => prev + 1);
            }}
            onClose={() => setSelected(null)}
            selected={selected}
            workType={workType}
            title={title}
          />
          <RecordsTable
            refreshCount={refreshCount}
            onDelete={() => setRefreshCount((prev) => prev + 1)}
            onSelect={(record) => {
              setSelected(record);
            }}
            workType={workType}
            title={title}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CustomInspections;
