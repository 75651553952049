import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const sparesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSparesUsage: builder.query({
      query: ({ refreshCount }) =>
        addParamsToUrl("v1/spares_usage", {
          refreshCount,
        }),
      onQueryStarted: requestHandler,
    }),
  }),
});

export const { useGetSparesUsageQuery } = sparesApi;
