import { rootApi, requestHandler } from ".";
import { addParamsToUrl } from "../Utils";

export const buoysApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBuoys: builder.query({
      query: ({ pageNo, pageSize, refreshCount }) =>
        addParamsToUrl("v1/buoys", { pageNo, pageSize, refreshCount }),
      onQueryStarted: requestHandler,
    }),
    saveBuoy: builder.mutation({
      query: (body) => ({
        url: "v1/buoys",
        method: "POST",
        body,
      }),
      onQueryStarted: requestHandler,
    }),
    deleteBuoy: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/buoys/${id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateBuoy: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/buoys/${id}`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    updateAdditionalBuoy: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `v1/buoys/${id}/additional`,
          method: "PUT",
          body,
        };
      },
      onQueryStarted: requestHandler,
    }),
    getBuoyById: builder.query({
      query: ({ id }) => `v1/buoys/${id}`,
      onQueryStarted: requestHandler,
    }),
  }),
});

export const {
  useGetBuoysQuery,
  useLazyGetBuoysQuery,
  useSaveBuoyMutation,
  useDeleteBuoyMutation,
  useUpdateBuoyMutation,
  useUpdateAdditionalBuoyMutation,
  useGetBuoyByIdQuery,
} = buoysApi;
